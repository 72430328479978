import React from 'react'
import styled from 'styled-components'
import Publish from '../../asset/desktop/DeFi/ribbonProtocol.jpeg';
import ribbon from '../../asset/desktop/ribbon.png'
import ghf from '../../asset/desktop/ghf.png'
import afya from '../../asset/desktop/afya.png'


const BgImg = styled.div`
  background: url(${Publish}) no-repeat;
  width: 100%;
  background-size: cover;

  /* .eachDiv{ */
    h3{
      color: #003168;
    /* } */
  }
  & + button{
    background: #FF6495;
  }
`;



const RibbonProtocol = () => {

  const ListArray = [
    {
      img: ribbon,
      text: "RIBBON Token for Protocol Governance",
      pTag: "The Ribbon Token is the Protocol’s multichain native Governance token. It is used for farming and monetizing the Ribbon Ecosystem."
    },
    {
      img: ghf,
      text: "GHF Token for Global Health Financing",
      pTag: "The GHF token is the Sore of Value token for Health Data, and is also used as a reward token for Stakers and for trading Health Data."
    },
    { 
      img: afya,
      text: "AFYA Stablecoin for Payments",
      pTag: "AFYA is the native Stablecoin token for the Ribbon Protocol and used for loans and incentive payments made to Patients and Practitioners and community Health Workers."
    },

  ]
  return (
    <div>
      <BgImg className="py-24 my-12">
        <div className=" container mx-auto px-4 md:px-0">
        <h2 className=" font-semibold text-4xl text-center mb-20 text-white">Ribbon Protocol Tokens</h2>
        <div className="grid gap-x-10 gap-y-8 md:grid-cols-3">
          {
            ListArray.map((item, i) => (
              <div key = {i} className="rounded-2xl bg-white py-24 px-10">
                <img src = {item.img} className = 'mb-3' />
                <h3 className="font-bold text-2xl mb-3">{item.text}</h3>
                <p>{item.pTag}</p>
              </div>
            ))
          }
        </div>
        </div>
      </BgImg>


      <button className="my-28 mx-auto block rounded text-white py-2 px-32 font-semibold">Go to DeFi Site</button>

    </div>
  )
}

export default RibbonProtocol
