import styled from "styled-components";

// responsiveness

export const Protocols = styled.section`
    overflow-y: hidden;
    /* overflow-x: hidden; */

    .main {
        font-family: "Greycliff-Regular", sans-serif;
        font-size: 1.125rem;
        max-width: 44rem;
        /* font-size: 2rem; */
    }

    @media screen and (max-width: 650px) {
        .main {
            font-size: 1rem;
            max-width: none;
        }

        .img_container_one {
            display: flex;
            justify-content: center;
            img {
                width: 20.3125rem;
                height: 16.06rem;
            }
        }

        .img_container_two {
            display: flex;
            justify-content: center;
            img {
                height: 14.31rem;
                width: 16.25rem;
            }
        }

        .a {
            margin-top: 2.5rem;
        }
    }

    @media screen and (max-width: 360px) {
        .b {
            margin-top: 2.5rem;
        }
    }
`;
