import React from 'react'
import styled from "styled-components";

// responsiveness
const Title = ({ text, bgc, reg, textColor }: any) => {
  return (
    <Div bgc={bgc} reg={reg} className="relative   p-4 h-24">
      {!reg && <h1 className="bg-text absolute w-full h-full left-0 z-0 font-bold ">
        {text}
      </h1>}
      <TheH1 textColor={textColor} className="text absolute left-0 text-4xl z-20 font-semibold">
        {text}
      </TheH1>
    </Div>
  );
}

export const Div = styled.div<any>`
  width: ${({ reg }) => (reg ? "100%" : "270px")};
  height: ${({ reg }) => (reg ? "70px" : "")};
  /* background-color:red; */
  h1.bg-text {
    color: ${({ bgc }) => bgc};
    font-size: 5rem;
    line-height: 1;
  }


`;


export const TheH1 = styled.h1<any>`
  font-size: ${({ reg }) => (reg ? "22px" : "")};
  margin-top: ${({ reg }) => (reg ? "0" : "")};
  font-weight: ${({ reg }) => (reg ? "bold" : "")};
  color: ${props => props.textColor ? props.textColor : "#161F6D"};
  padding: 1.5rem 0;
  /* margin-top: -0.rem; */

&::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 5px;
  background-color: #ff6495;
}
`;

export default Title
