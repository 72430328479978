import React from 'react'
import InformationPage from '../../components/Information'
import Cards from '../DeFi/Cards'
import Others from './Others'

import inforPicture from '../../asset/desktop/Web/Tecnology.png'
import info from '../../asset/desktop/Web/goverance.png'


import smart from '../../asset/desktop/Web/smart.png'
import phone from '../../asset/desktop/Web/phone.png'
import p2p from '../../asset/desktop/Web/p2p.png'
import DAO from '../../asset/desktop/Web/DAO.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const Web = () => {

  const cardsArray = [
    {
      img: smart,
      heading: "Smart Contracts",
      text: "All value transfer within the Ribbon Protocol is governed by audited smart contracts."
    },
    {
      img: phone,
      heading: "USSD Technology for Feature Phones",
      text: "Users in developing countries are not excluded from interacting with the platform through our USSD wallet interface that is built in house."
    },
    {
      img: p2p,
      heading: "Peer to Peer Crypto Cards for Online/Offline Payments",
      text: "We integrate with SEMPO to bring Crypto Debit Cards that process payments Peer to Peer with or without internet connection."
    },
    {
      img: DAO,
      heading: "DAO for Fund Governance",
      text: "Allocation of funding to specific healthcare programs and other ecosystem wide configurations are governed by a decentralized autonomous organization."
    }
  ]

  return (
    <div data-aos = 'slide-left'>
      <InformationPage
        title="Web 3.0"
        textShadow={inforPicture}
        headerImg={info}
      />
      <Cards
      headerText="Web 3.0"

        body1=" Ribbon is built on the latest Web3.0 technology. 
        The Ribbon ecosystem is built on a number of the latest 
        blockchain technologies and primitives. 
        All logic, value transfer and smart contracts run on the Ethereum 
        blockchain"

        body2="Our whole architecture and code base will be 
        open source! Developer documentation and a wiki are coming soon. 
        Check out our github below.
        "

        cardsArray={cardsArray}
      />

      <Others />
    </div>
  )
}

export default Web
