import React from 'react'
import styled from 'styled-components'
import Protocol from '../../asset/desktop/Protocol2.png'
import ProtocolMobile from '../../asset/mobile/Protocol2.png'
import Line from '../../asset/desktop/line.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const Ribbon = () => {
    return (
        <RibbonPage data-aos = 'slide-right'>
            <div className = 'lg:grid grid-cols-2 px-5 sm:px-14 my-auto content gap-20'>
                <section className = 'sec-one'>
                    <img src = {Protocol} alt = "protocol" className = "sm:block hidden w-full" />
                    <img src = {ProtocolMobile} alt = "protocol" className = "sm:hidden block w-full" />
                </section>

                <section className = 'sec-two lg:flex flex-col justify-between mt-7 lg:mt-0'>
                    <div className = 'lg:mb-0 mb-5'>
                        <img src = {Line} alt = 'line.png' className = 'lg:mb-2 mb-2'/>
                        <h2 className = 'lg:text-2xl'>What is Ribbon Protocol?</h2>
                    </div>
    
                    <p>
                        Ribbon Protocol is a blended suite of open Web 3.0 technologies 
                        that function as a global computer running on a secure Blockchain 
                        network to deliver accurate health information and 
                        leverage it to finance Global Health and Public Health.
                    </p> <br className = 'block lg:hidden' />

                    <p>
                        Ribbon Protocol views Global Health and Public Health as massive 
                        entities in the same likeness as FAANG (Facebook, Apple, Amazon, Netflix and Google) 
                        which run on multiples of corporate software and technologies.
                    </p> <br className = 'block lg:hidden' />

                    <p>
                        As such, Ribbon Protocol offers a free simplified and custom 
                        designed version of these corporate systems, to leverage the 
                        most valuable asset in health which is health information, and 
                        utilize it as leverage and collateral to provide access to 
                        financing for Global Health and Public Health programs.
                    </p>
                </section>
            </div>
        </RibbonPage>
    )
}

const RibbonPage = styled.div`
    background: #FFF8FA;
    height: 650px;
    display: flex;
    align-items: center;

    .sec-two {
        h2 {
            color: #000852;
            font-family: "Greycliff-Bold", sans-serif;
            font-size: 2.125rem;
        }

        p {
            max-width: 33.31rem;
            font-family: "Greycliff-Regular";
            font-size: 1.125rem;
        }
    }

    @media screen and (min-width: 800px) and (max-width: 1023px) {
        height: 1150px;

        .sec-two {
            p {
                max-width: none;
            }
        }
    }

    @media screen and (max-width: 800px) {
        height: 950px;

        .sec-two {
            p {
                max-width: none;
            }
        }
    }

    @media screen and (max-width: 650px) {
        .sec-two {
            h2 {
                font-size: 1.25rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }
`

export default Ribbon
 