import React from 'react'
import styled from 'styled-components'
import HowCommon from './HowCommon'
import bg from '../../asset/desktop/zigzag.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const How = () => {
    return (
        <Question className = 'sm:py-20 py-10 px-5 sm:px-0 my-5 sm:my-0' data-aos = 'slide-left'>
            <section className = 'sm:mb-10 text-center'>
                <h2>How Ribbon Works</h2>
                <p className = 'mx-auto text'>
                    The Ribbon Protocol leverages the value of Cryptocurrency 
                    Ecosystem as a source of new digital 
                    asset capital to fund gaps in Global Health Financing.
                </p>
            </section>

            <section className = 'desktop'>
                <div className = 'grid grid-cols-1 gap-y-5 sm:grid-cols-2 sm:gap-y-20 sm:text-center mt-5 sm:mt-0'>
                    <div className = 'max-w-md'>
                        <HowCommon 
                            num = "01"
                            title = "Incentives"
                            text = "The Ribbon Incentives App is the tool used by Community Health Workers, Practitioners, Patients and Individuals to capture and digitize personal health data while earning Tokenized Incentives for every set of collected data."
                        />           
                    </div>

                    <div className = 'max-w-md place-self-end'>
                        <HowCommon 
                            num = "02"
                            title = "Health Data Marketplace"
                            text = "Tokenized health data, reports, analytics and insights produced by the Ribbon Incentives App, and uploaded in the Marketplace can be traded on the Ribbon Marketplace if or when it aligns with the Ribbon Protocol specifications."
                        />           
                    </div>

                    <div className = 'max-w-md defi flex items-center'>
                        <HowCommon 
                            num = "03"
                            title = "Decentralized Finance"
                            text = "Ribbon's DeFi platform enables Holders of Cryptocurrencies to invest, provide liquidity, stake, lend and borrow digital assets securely, and earn ROI tied to the ever increasing commercial value of Tokenized global health data and information."
                        />           
                    </div>
                    
                    <div className = 'max-w-md place-self-end items center ghf flex items-center'>
                        <HowCommon 
                            num = "04"
                            title = "Global Health Financing"
                            text = "DeFi further enables direct Institutional financing to global Health and public health programs, without any intermediaries using Tokenized global health data as a collateral asset to back the loans."
                        />           
                    </div>
                </div>
            </section>
        </Question>
    )
}

const Question = styled.div`
    background: url(${bg});
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
        color: #041F3C;
        font-size: 2.1em;
        font-family: 'Work Sans', sans-serif;
    }

    .desktop {
        max-width: 1000px;
        margin: 0 auto;
    }

    .text {
        max-width: 38rem;
        font-family: 'Hind', sans-serif;
        font-size: 1.125rem;
    }

    .defi {
        border: 1px solid #D2D2D2;
        border-radius: 20px;
        width: 564px;
        height: 400px;
        padding: 0 1rem;
    }

    .ghf {
        width: 564px;
        height: 400px; 
    }

    @media screen and (max-width: 650px) {
        background: #F0F7FF;
        
        .defi {
            border: none;
            height: auto;
            width: auto;
            padding: 0px;
        }

        .ghf {
            width: auto;
            height: auto;
        }

        h2 {
            font-size: 1.25rem;
        }

        .text {
            font-size: 1rem;
        }
    }
`

export default How
