import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../asset/desktop/logo.png";
import telle from "../../asset/desktop/telle.png";
import icon3 from "../../asset/desktop/icon3.png";
import twitter from "../../asset/desktop/twitter.png";
import at from "../../asset/desktop/at.png";
import { IoLogoInstagram, IoLogoFacebook, IoLogoYoutube } from "react-icons/io";
import { SiTiktok, SiMedium, SiTwitter, SiInstagram, SiDiscord, SiTelegram} from "react-icons/si";

const index = () => {
  return (
    <Foot className="sm:px-10 text-white px-5">
      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:py-20 py-10 gap-y-8 md:gap-y-0">
        <div className="logo-container">
          <div className="mb-3">
            <img src={logo} alt="logo" />
          </div>
          <p className="mb-6 sm:mb-0 for">DeFi for Global Health.</p>
        </div>

        <div>
          <h3>About</h3>

          <ul>
            <li>
              <Link to="/about/protocol">Protocol</Link>
            </li>
            <li>
              <Link to="/about/incentives">Incentives</Link>
            </li>
            <li>
              <Link to="/about/web">Technology</Link>
            </li>
            <li>
              <Link to="/about/information">Information</Link>
            </li>
            <li>
              <Link to="/about/people">People</Link>
            </li>
            <li>
              <Link to="/about/tokenomics">Tokenomics</Link>
            </li>
            <li>
              <Link to="/about/games">Games</Link>
            </li>
          </ul>
        </div>

        <div>
          <h3>Docs</h3>
          <ul>
            <li>
              <Link to="/docs/governance">Governance</Link>
            </li>
            <li>
              <Link to="/docs/De-Fi">DeFi</Link>
            </li>
          </ul>

          <div className="mt-3 md:mt-10">
            <span className="font-bold text-lg sm:text-2xl">Useful Links</span>

            <div className="flex mt-2">
              <a
                href="https://t.me/RibbonProtocol"
                target="_blank"
                className="mr-3 bg-[#00031E] px-2 flex justify-center py-2 items-center"
              >
                {/* <img src={telle} alt="telle" /> */}
                <SiTelegram size={24} color="#D9EBFF" />
              </a>
              <a
                href=" https://discord.gg/HWa2b9H3qy"
                target="_blank"
                className="mr-3 bg-[#00031E] px-2 flex justify-center items-center py-2"
              >
                <SiDiscord size={24} color="#D9EBFF" />
              </a>
              <a
                href="https://www.twitter.com/RibbonProtocol"
                target="_blank"
                className="mr-3 bg-[#00031E] px-2 flex justify-center py-2 items-center"
              >
                {/* <img src={twitter} alt="twitter" /> */}
                <SiTwitter size={24} color = "#D9EBFF" />
              </a>
              <a
                href="https://www.instagram.com/ribbonprotocol"
                target="_blank"
                className="bg-[#00031E] px-2 flex justify-center py-2 items-center"
              >
                <SiInstagram size={24} color="#D9EBFF" />
              </a>
            </div>

            <div className="flex mt-2">
              <a
                href="https://www.facebook.com/ribbonprotocol/"
                target="_blank"
                className="bg-[#00031E] px-2 flex justify-center py-2 items-center mr-3"
              >
                <IoLogoFacebook size={24} color="#D9EBFF" />
              </a>

              <a
                href="https://www.youtube.com/channel/UC1a6Mi0VCWM9GLk81UZyAxA/featured"
                target="_blank"
                className="bg-[#00031E] px-2 flex justify-center py-2 items-center mr-3"
              >
                <SiTiktok size={24} color="#D9EBFF" />
              </a>

              <a
                href="https://www.tiktok.com/@ribbonprotocol"
                target="_blank"
                className="bg-[#00031E] px-2 flex justify-center items-center py-2 mr-3"
              >
                <IoLogoYoutube size={24} color="#D9EBFF" />
              </a>

              <a
                href="https://medium.com/@RibbonProtocol"
                target="_blank"
                className="bg-[#00031E] px-2 flex justify-center items-center"
              >
                <SiMedium size={24} color="#D9EBFF" />
              </a>
            </div>
          </div>
        </div>

        <div>
          <h3>Help & Support</h3>
          <ul>
            <li>
              <Link to="https://defi.ribbonprotocol.org/">DeFi</Link>
            </li>

            <li>
              <Link to="https://app.ribbonprotocol.org/">Ribbon App</Link>
            </li>

            <li>
              <Link to="/token/help">FAQ</Link>
            </li>

            <li>
              <Link to="/token/t&c">Token T&C</Link>
            </li>
          </ul>
        </div>
      </section>

      <div className="flex justify-between">
        <p className="pb-5">
          2021 <img src={at} alt="at" className="inline-block" /> ribbonprotocol
          | All Rights Reserved
        </p>

        <p className="hidden md:block">
          <a href="mailto:hello@ribbonprotocol.org">hello@ribbonprotocol.org</a>
        </p>
      </div>
    </Foot>
  );
};

const Foot = styled.div`
  background: #000852;

  h3 {
    font-family: "Hind", sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: 1.2rem;
  }

  .for {
    font-family: "Hind", sans-serif;
    font-weight: 500;
  }

  a img {
    background: #00031e;
    padding: 5px;
  }

  .logo-container img {
    height: 70px;
  }

  li {
    font-family: "Hind", sans-serif;
    margin-bottom: 0.9rem;
    font-size: 1.115rem;
    font-weight: 400;
  }

  @media screen and (max-width: 650px) {
    /* h3 {
            margin-bottom: .5rem;
        } */
  }
`;

export default index;
