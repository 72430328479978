import React from 'react'
import InformationPage from '../../components/Information'
import Health from '../../components/Information/health'
import Ratings from '../../components/Information/ratings'

import inforPicture from '../../asset/desktop/Information.png'
import info from '../../asset/desktop/info.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const index = () => {
    return (
        <div data-aos = 'slide-right'>
            <InformationPage
                title="Information"
                textShadow={inforPicture}
                headerImg={info} />
            <Health
                headerText="Health Information Assets"
                body1=" The Ribbon Protocol goes beyond digital money and information.
                Any health information can now be represented,
                collateralized, traded and put to use as Tokenized Digital Assets
                and Non-fungible tokens (NFTs)."

                body2="You can tokenize your X-Rays, Sonograms, Health Records,
                Test Results  and get
                royalties automatically every time they are utilized or re-sold.
                You can also use a your Health Information as collateral to take out
                a DeFi loan."

                body3="The possibilities and new ways to unlock the value in health information
                will grow exponentially over time with increased adoption of the Ribbon
                Protocol.."
            />
            <Ratings />
        </div>
    )
}

export default index
