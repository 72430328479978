import LazyLoad from "react-lazyload";
import GameHeroImage from "../../asset/gamehero.png";
import GameHeroImageMobile from "../../asset/mobile/games.png";
import Title from "../common/Title";
import Group from "../../asset/desktop/Group.png";
import patientIco from "../../asset/desktop/patientIco.png";
import playUnderlay from "../../asset/desktop/playUnderlay.png";
import RibbonGames from "../../asset/desktop/ribbongames.png";

export default function GameHero() {
  return (
    <div className="relative">
      <LazyLoad once>
        <img
          src={GameHeroImage}
          alt="game"
          className="w-full hidden sm:block"
        />
        <img
          src={GameHeroImageMobile}
          alt="game"
          className="w-full sm:hidden"
        />
      </LazyLoad>

      <div className="absolute top-0 mt-[12rem] md:mt-[20rem] px-4 md:px-[3.75rem]">
        <div>
          <img src={RibbonGames} alt="ribbongame" />
        </div>

        <div className="relative bottom-16 md:bottom-20">
          <p className="text-6xl font-gcb text-white after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 sm:after:-mt-8 after:bg-[#ff6495]">
            Game
          </p>
        </div>
      </div>

      <div className="absolute top-80">
        <img src={Group} alt="group" className="w-full h-full" />
      </div>

      <section className="bg-[#161F6D] text-white py-24 h-[490px] px-4">
        <div className="max-w-[40rem] mx-auto">
          <Title text="Artificial Life" textColor="#EBEBEB" reg />
          <p>
            Artificial life (often abbreviated ALife or A-Life) is a field of
            study wherein researchers examine systems related to natural life,
            its processes, and its evolution, through the use of simulations
            with computer models, robotics, and biochemistry.
          </p>
        </div>
      </section>

      <section className="font-gcr max-w-[50rem] sm:mx-auto border border-[#98C3FF] mx-4 px-5 pb-4 sm:px-9 rounded-2xl sm:pb-8 -mt-32 bg-white">
        <div className="-mt-16 mb-5">
          <img
            src={patientIco}
            alt="patientIoco"
            className="w-[61px] h-[82px] sm:w-[75px] sm:h:[100px]"
          />
        </div>

        <h3 className="text-[#003168] text-xl md:text-2xl font-gcb mb-4 md:mb-6">
          Ribbon Artificial Life Game
        </h3>
        <p className="text-base md:text-lg">
          The Ribbon Protocol enables Users to mint their Socioeconomic & Health
          Data into an NFT and use it to access a Play-to-Earn metaverse game
          that simulates and virtualizes their health profile into a playable
          character. The User can then complete various lifestyle and health
          related incentivized tasks which predict and forecasts the users
          health status along the way, thereby educating while entertaining the
          user.
        </p>
      </section>

      <section className="relative md:-mt-6 md:h-[578px]">
        <div>
          <LazyLoad once>
            <img
              src={playUnderlay}
              alt="play"
              className="w-full h-[578px] object-cover bg-[#FFCADB1A] hidden sm:block"
            />
          </LazyLoad>

          <div className="sm:absolute top-0 flex flex-col h-full pt-32 pb-8 md:pb-0 md:pt-44 -mt-20 md:-t-0 bg-[#FFCADB1A] md:bg-transparent px-4 md:px-0">
            <h3 className="font-gcb text-[#003168] sm:text-center mb-12 sm:mb-16 text-4xl">
              Play, Learn & Earn
            </h3>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 md:px-24">
              <div className="relative">
                <p className="text-[#003168] text-2xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[40px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
                  Play-to-win
                </p>
                <p className="text-lg font-gcr">
                  The game empowers users to learn about their health and how to
                  improve it through entertainment and real earnings, thereby
                  turning health education and entertainment into a source of
                  income.
                </p>
              </div>

              <div className="relative">
                <p className="text-[#003168] text-2xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[40px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
                  Staking / Farming
                </p>
                <p className="text-lg font-gcr">
                  Users can stake or farm their earned Ribbon tokens to help
                  securizing in-game network activities and get rewarded for
                  depositing and holding coins, with constant guaranteed
                  time-based returns.
                </p>
              </div>

              <div className="relative">
                <p className="text-[#003168] text-2xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[40px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
                  Virtual Goods
                </p>
                <p className="text-lg font-gcr">
                  The game allows user to use their earned Ribbon tokens buy
                  in-game Virtual Real Estate and Virtual goods to empower users
                  to improve their socioeconomic knowledge through educational
                  entertainment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
