import React from 'react'
import styled from 'styled-components'

const healthCard = ({src, heading, text}) => {
    return (
        <Card>
            <section className = 'sm:pt-10 sm:pl-6 sm:pb-14 py-10 pl-6'>
                <img src = {src} alt = "pics" className = 'mb-11 h-32' />
                <p className = 'mb-5 heading capitalize'>{heading}</p>
                <p className = 'text'>{text}</p>
            </section>
        </Card>
    )
}

const Card = styled.div`
    width: 40.25rem;
    /* height: 29.875rem; */
    margin: auto;
    border: 1px solid #98C3FF;
    background: #ffffff;
    border-radius: 20px;

    .heading {
        color: #003168;
        font-family: GreyCliff-Bold, sans-serif;
        font-size: 1.3rem;
        line-height: 1.89rem;
    }

    .text {
        font-family: GreyCliff-Regular, sans-serif;
        font-size: 1.125rem;
        line-height: 1.68rem;
        max-width: 32.68rem;
    }

    @media screen and (max-width: 650px) {
        width: 21.43rem;
        width: 100%;
        
        img {
            height: 88px;
            width: 88px;
        }

        .heading {
            font-size: 1.25rem;
        }

        .text {
            font-size: 1rem;
            line-height: 1.6rem;
            max-width: none;
        }
    }
`

export default healthCard
