import { NavLink } from "react-router-dom";
import TokenomicsImage from "../../asset/desktop/Tokenomics/tokenomicsImage.png";
import LazyLoad from "react-lazyload";
import Title from "../../components/common/Title";

export default function Tokenomics() {
  return (
    <div className="mt-8 px-4 sm:px-6 lg:px-16">
      <div className="md:flex flex-row items-center justify-between gap-y-16 mb-8">
        <div className="mb-16 md:mb-0">
          <Title text="Tokenomics" bgc="#F0F7FF" />
        </div>
        <div className="flex justify-center">
          <LazyLoad once>
            <img src={TokenomicsImage} />
          </LazyLoad>
        </div>
      </div>

      <section className="grid grid-cols-1 md:grid-cols-4 gap-x-6">
        <div className="bg-[#F9FAFF] px-2 md:px-10 md:pt-12 md:pb-20">
          <h3 className="md:text-lg text-[#4D4D4D] font-gcr mb-4">
            Table of Contents
          </h3>
          <hr />

          <div>
            <ul className="grid gap-y-10 mt-10">
              <li>Overview and History</li>
              <li>Tokenomics</li>
              <li>Technology</li>
              <li>Contributors</li>
              <li>Advisors</li>
            </ul>
            <NavLink to="/"></NavLink>
            <NavLink to="/"></NavLink>
            <NavLink to="/"></NavLink>
            <NavLink to="/"></NavLink>
          </div>
        </div>

        <div className="col-span-3"></div>
      </section>
    </div>
  );
}
