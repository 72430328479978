import LazyLoad from "react-lazyload";
import { Protocols } from "./styles";
import styled from "styled-components";
import proImg from "../../asset/desktop/Protocol.png";
import patient from "../../asset/mobile/patient.png";
import group from "../../asset/desktop/Group.png";
import hammer from "../../asset/mobile/hammer.png";
import cover from "../../asset/mobile/cover.png";
import baloon from "../../asset/mobile/ballon.png";
import Title from "../../components/common/Title";
import AOS from "aos";
import "aos/dist/aos.css";

const Img = styled.img`
  @media screen and (max-width: 650px) {
    height: 225px;
    width: 202px;
  }
`;

AOS.init({
  delay: 50,
  duration: 400,
});

const Protocol = () => {
  return (
    <Protocols className="my-4" style={{ overflow: "hidden" }}>
      <div className="overlay relative">
        <div className="section_one " data-aos="flip-left">
          <div className="media_content   md:flex md:items-center md:justify-between p-4">
            <div className="empty_title md:ml-20 " data-aos="slide-right">
              <Title text="Protocol" bgc="#FFF8FA" />
            </div>
            <div
              className="img_container_one  w-full md:w-1/2 md:mr-16  mt-4 px-4 py-8 sm:p-8"
              data-aos="slide-left"
            >
              <LazyLoad once>
                <img src={proImg} alt="" className="w-full h-full" />
              </LazyLoad>
            </div>
          </div>
          <div className="img hidden md:block absolute top-48 h-64 ">
            <img src={group} alt="" className="" />
          </div>
          <div className="info bg-text-title w-full md:mt-6 md:h-96 md:px-28 p-8 flex items-center justify-center text-center ">
            <p className="main text-white my-6 md:w-3/5" data-aos="zoom-in">
              The Protocol layer of the Ribbon Ecosystem is a set of rules that
              governs the collection of accurate health information and the way
              it is stored, handled, accessed, analyzed, priced, monetized,
              collateralized and beneficiated in multiple ways and forms to
              unlock value and insights for improved decision making and health
              outcomes.
            </p>
          </div>
        </div>
        {/*  */}
        <div className="section_two  bg-sec-1 mt-6 md:mt-0 md:flex md:flex-row-reverse items-center md:justify-between md:py-28">
          <div
            className="img_container w-full md:w-96 md:mr-32 mt-4 px-4 py-8 sm:p-8 flex justify-center sm:block"
            data-aos="slide-left"
          >
            <Img src={hammer} alt="" className="w-full h-full" />
          </div>

          <div
            className="info text-left px-4 py-8 sm:p-8 md:ml-20 md:mt-6  md:h-80 md:w-1/2"
            data-aos="slide-right"
          >
            <Title text="Regulatory" bgc="#fff" reg />
            <p className="text text-text-dark text-lg ">
              The Protocol rules are designed in alignments with prevailing
              Global Health Laws and Regulations such as National Health Acts,
              Privacy and Protection of Personal Information and Identities Acts
              as well as other Ethics and Humanities Laws and Regulations that
              seek to guard the privacy, dignity and rights of patients and
              individuals.
            </p>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="section_three bg-sec-pink md:py-20 md:flex md:justify-center md:items-center mt-8">
        <div
          className="img_container w-full mt-4 px-4 py-8 sm:p-8 md:w-80  flex justify-center items-center "
          data-aos="fade-right"
        >
          <img src={baloon} alt="" className="w-64 md:w-full h-full" />
        </div>

        <div
          className="info text-left px-4 py-8 sm:p-8 text-lg md:w-1/2 md:ml-16  "
          data-aos="fade-left"
        >
          <Title text="Equitable Health and Finance" reg bgc="#fff" />
          <p className="a text text-text-dark text-md md:leading-6">
            There are billions of people around the world who do not have access
            to equitable healthcare and financial services, which are key
            foundational pillars for development as outlined in the SDGs. The
            Ribbon Protocols’ blending of healthcare and finance over a
            multi-chain Blockchain network unlocks the value of health
            information and enables anyone anywhere to utilize their personal
            health information value to access healthcare and finance.
            <br />
            <br />
            The decentralized nature of the Ribbon Protocol guarantees open
            access to any verifiable person without prejudice or discrimination,
            and only requires an internet connection to get started anywhere in
            the world.
          </p>
        </div>
      </div>

      <div className="bg-white md:flex md:flex-row-reverse py-20 md:justify-between md:mx-40">
        <div
          className="img_container w-full md:w-80 flex justify-center items-center  mt-4 px-4 py-8 sm:p-8"
          data-aos="fade-right"
        >
          <img src={patient} alt="" className="w-64 h-full" />
        </div>

        <div
          className="info text-left px-4 py-8 sm:p-8 text-lg md:w-1/2"
          data-aos="fade-left"
        >
          <Title text="Patient First" reg bgc="#fff" />
          <p className="text text-lg">
            The Protocol rules abides to a Patient First philosophy, where
            Patient is the anchor and primary beneficiary of the whole Ribbon
            Protocol, and where all design decisions are made in service to the
            Patient. The intention behind this way of thinking is to design a
            Global Health system that puts the Patient at the center of the care
            continuum and competes in quality of care to serve the Patient.
          </p>
        </div>
      </div>

      {/*  */}

      <div className="section_five bg-sec-blue mb-8 md:flex md:justify-between md:px-11 md:py-16 md:items-center">
        <div
          className="img_container_two w-full md:w-1/3 md:-mb-20  flex justify-center sm:block mt-4 p-6"
          data-aos="slide-up"
        >
          <img src={cover} alt="" className="w-full h-full" />
        </div>

        <div
          className="info text-left px-4 py-8 sm:p-8 text-lg md:w-1/2"
          //   data-aos="flip-right"
        >
          <Title text="Positive Sum Healthcare" reg bgc="#fff" />
          <p className="text b text-text-dark text-lg">
            The Protocol is guided by a Non Zero Sum or Positive Sum game
            theory, where all participants including competitors add value, are
            incentivized and benefit as a collective from the compound network
            effect value generated by the ecosystem.
          </p>
        </div>
      </div>
    </Protocols>
  );
};

export default Protocol;

// export default function Protocol() {
//     return (
//         <div className="mt-8 font-gcb text-5xl !text-red-500">This is the protocol page</div>
//     )
// }
