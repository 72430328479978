import Title from "../../components/common/Title";
import styled from "styled-components";

import hammer from "../../asset/mobile/hammer.png";

import HeaderImg from '../../asset/desktop/incentives/incentives.jpg'
import CHWImg from '../../asset/desktop/incentives/communityworker.png'
import PractitionersImg from '../../asset/desktop/incentives/doctor.png'
import HealthCare from '../../asset/desktop/incentives/doctorincentives.png'
import pharmaceuticals from '../../asset/desktop/incentives/pharmaceuticals.png'
import patient from '../../asset/desktop/incentives/patient.png'

import line from '../../asset/desktop/line.png'
import zigzag from '../../asset/desktop/Group.png'
import LazyLoad from "react-lazyload";

const Img = styled.div`
  @media screen and (max-width: 650px) {
    .healthcare {
      height: 18.75rem;
      width: 14.43rem;
    }

    /* .hammer {
      height: 14.06rem;
      width: 12.625rem;
    }

    .healthcare {
      height: 18.75rem;
      width: 14.43rem;
    }

    .community {
      height: 17.875rem;
      width: 15.625rem;
    } */
  }
`

const Patient = styled.div`
  background: #161F6D;
  padding-top: 4.875rem;
  position: relative;

  .img {
    height: 21.68rem;
    width: 17.8rem;
  }

  .pat {
    padding-top: 8.4rem;
    padding-bottom: 5.06rem;
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .first {
    max-width: 47.25rem;
    margin: 0 auto;
    font-family: "GreyCliff-Regular", sans-serif;
    font-size: 1.125rem;
  }

  .title {
    font-size: 2.125rem;
    font-family: "GreyCliff-Bold", sans-serif;
  }

  .title-text {
    font-size: 1.125rem;
    font-family: "GreyCliff-Regular";
    line-height: 1.7rem;
  }

  .zigzag {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 130%;
    width: 100%;
    background-position: cover;
  }

  @media screen and (max-width: 650px) {
    padding-top: 4rem;
    position: relative;

    .img {
      height: 15.375rem;
      width: 12.5rem;
    }

    .pat {
      padding-top: 3.5rem;
      padding-bottom: 2.68rem;
      padding-left: 0;
      padding-right: 0;
    }

    .first {
      max-width: none;
      font-size: 1.125rem;
    }

    .title {
      font-size: 1.25rem;
    }

    .title-text {
      font-size: 1rem;
      line-height: 1.5rem;
    } 

    .img {
        justify-self: center;
    }
  }
`

const P = styled.p`
  @media screen and (max-width: 360px) {
    margin-top: 2rem;
  }
`

const On = styled.p`
  @media screen and (max-width: 460px) {  
    margin-top: 2.5rem;  
  }
`

const Incentives = () => {
  return (
    <div style = {{overflowX: "hidden"}}>
      <section className='media_content md:flex md:items-center md:justify-between p-4'>
        <div
          className='empty_title md:ml-20 '
          data-aos='slide-right'>
          <Title text='Incentives' bgc='#FFF8FA' />
        </div>
        <div
          className='img_container  w-full md:w-1/2 md:mr-16  mt-4 px-4 py-8 sm:p-8'
          data-aos='slide-left'>
          <LazyLoad once>
            <img src={HeaderImg} alt='' className='w-full h-full' />
          </LazyLoad>
        </div>
      </section>


      <Patient className = 'text-white px-4 sm:px-0'>
        <img src = {zigzag} alt = 'zig-zag' className = 'zigzag hidden md:block object-fit' />

        <div className = 'first text-center'>
          The Ribbon Protocol provides value to every player within 
          the public healthcare ecosystem  through tokenized incentives 
          (paid out in AFYA stablecoin) for health and wellness initiatives 
          that encourage improve health outcomes
        </div>

        <section className = 'grid grid-col-1 sm:grid-cols-2 pat'>
          <div className = 'img'>
            <img src = {patient} alt = 'patient' className = 'w-full h-full' />
          </div>

          <div className = 'mt-8 sm:mt-0'>
            <div className = 'mb-3'>
              <img src = {line} alt = 'line' />
            </div>
            <div className = 'title mb-5 align-self-center'>Patients</div>
            <p className = 'title-text'>
              We offer a personal data broking service to all individuals that 
              empowers them to retain sovereign ownership of their health records 
              and data while earning DAI at every instance where their 
              datasets are traded or monetized in the data economy.
            </p>
          </div>
        </section>
      </Patient>      

      {/*  */}
      <Div className='section_two bg-sec-blue mt-6 md:flex md:flex-row-reverse items-center md:justify-between md:py-28'>
        <div
          className='img_container_one w-full md:w-96 md:mr-32 mt-4 px-4 flex justify-center sm:block py-8 sm:p-8'
          data-aos='slide-left'>
          <img src={CHWImg} alt='' className='w-full h-full community' />
        </div>

        <div
          className='info text-left px-4 py-8 sm:p-8 md:ml-20 md:mt-6  md:h-80 md:w-1/2'
          data-aos='slide-right'>
          <Title text='Community Health Workers' bgc='#fff' reg />
          <div className='text text-text-dark text-lgmt mt-10  sm:mt-0'>
            We onboard skilled and previously unemployed Community
            Health Workers as self-employed Care Partners, to function as
            intermediaries and patient advocates that process
            patient-practitioner medical interactions and prescription data.
          </div>
        </div>
      </Div>


      {/*  */}
      <Div className='section_three bg-sec-pink md:py-20 md:flex md:justify-center md:items-center mt-8'>
        <div
          className='img_container_two w-full mt-4 px-4 py-8 sm:p-8 md:w-80  flex justify-center items-center '
          data-aos='fade-right'>
          <img
            src={PractitionersImg}
            alt=''
            className='w-64 md:w-full h-full'
          />
        </div>

        <div
          className='info text-left px-4 py-8 sm:p-8 text-lg md:w-1/2 md:ml-16  '
          data-aos='fade-left'>
          <Title text='Practitioners' reg bgc='#fff' />
          <p className='text text-text-dark text-md md:leading-6'>
            We channel Incentivizes to healthcare practitioners for:
            Quality healthcare delivery, Quantity healthcare delivery, Performance,
            Patient interactions.
          </p>
        </div>
      </Div>

      {/*  */}
      <Div className='section_two bg-white mt-6 md:flex md:flex-row-reverse items-center md:justify-between md:py-28'>
        <div
          className='img_container_three md:w-96 md:mr-32 mt-4 md:p-8'
          data-aos='slide-left'>
          <img src={HealthCare} alt='' className='w-full h-full' />
        </div>

        <div
          className='info text-left px-4 py-8 sm:p-8 md:ml-20 md:mt-6  md:h-80 md:w-1/2'
          data-aos='slide-right'>
          <Title text='Health Care Providers' bgc='#fff' reg />
          <p className='text text-text-dark text-lg hey'>
            Healthcare providers such as Clinic, Hospitals,
            Program Implementing Partners, NGOs etc get digital
            tools and dashboards to manage their Workforce,
            Patients and the care continuum.
          </p>
        </div>
      </Div>


      {/*  */}
      {/*  */}
      <div className='section_three bg-sec-blue md:py-20 md:flex md:justify-center md:items-center mt-8'>
        <div
          className='img_container_four w-full mt-4 px-4 py-8 sm:p-8 md:w-80 flex justify-center items-center '
          data-aos='fade-right'>
          <img
            src={pharmaceuticals}
            alt=''
            className='w-64 md:w-full h-full'
          />
        </div>

        <div
          className='info text-left px-4 py-8 sm:p-8 text-lg md:w-1/2 md:ml-16  '
          data-aos='fade-left'>
          <Title text='Pharmaceuticals' reg bgc='#fff' />
          <p className='text text-text-dark text-md md:leading-6'>
            Pharmaceuticals gain access to public health
            patient-practitioner insights such as drug usage,
            brand performance, adherence, toxicity,
            patient-reported outcomes, etc; to help them
            in planning and business optimization.
          </p>
        </div>
      </div>

      {/*  */}
      <Div className='section_two bg-sec-off mt-6 md:flex md:flex-row-reverse items-center md:justify-between md:py-28'>
        <div
          className='img_container_five md:w-96 md:mr-32 mt-4 md:p-8'
          data-aos='slide-left'
          >
          <img src={hammer} alt='' className='w-full h-full hammer' />
        </div>

        <div
          className='info text-left px-4 py-8 sm:p-8 md:ml-20 md:mt-6  md:h-80 md:w-1/2'
          data-aos='slide-right'>
          <Title text='Government' bgc='#fff' reg />
          <p className='text text-text-dark text-lg '>
            The Protocol is positioned as a low cost automated
            system to help Governments improve health systems
            efficiency, manage patients, practitioners, prescriptions,
            inventory and to provide accurate reporting and data
            analytics.
          </p>
        </div>
      </Div>
    </div>
  )
}

const Div = styled.div`
  @media screen and (max-width: 650px) {
    .img_container_five {
      display: flex;
      justify-content: center;
      img {
        height: 16.06rem;
        width: 14.43rem;
      } 
    }

    .img_container_four {
      display: flex;
      justify-content: center;
      img {
        height: 17.06rem;
        width: 15.06rem;
      }
      
    }

    .img_container_three {
      display: flex;
      justify-content: center;
      img {
        height: 18.9rem;
        width: 14.4rem;
      }
      
    }

    .img_container_two {
      display: flex;
      justify-content: center;
      img {
        height: 18.5rem;
        width: 12.625rem;
      } 
    }

    .img_container_one {
      display: flex;
      justify-content: center;
      img {
        height: 13rem;
        width: 16.75rem;
      } 
    }
  }

  @media screen and (max-width: 327px) {
    .hey {
      margin-top: 2.5rem;
    }
  }
`

export default Incentives
