import styled from 'styled-components'
import ratingsImage from '../../asset/desktop/rating.png'
import ratingMobile from '../../asset/mobile/rating.png'
import line from '../../asset/desktop/line.png'

const ratings = () => {
    return (
        <Ratings>
            <section className = "flex md:justify-between flex-col md:flex-row md:items-center">
                <div className = 'block md:hidden mb-11 place-self-center'>
                    <img src = {ratingMobile} alt = "ratings" />
                </div>

                <div>
                    <img src = {line} className = 'mb-3' />
                    <h1 className = 'mb-5'>Ratings</h1>
                    <p>
                        Our Patient Experience of Care (PEC) rating system enables patients to 
                        rate their healthcare experience to help identify areas for continuous 
                        improvement on the quality of healthcare delivery at both healthcare 
                        practitioner and healthcare institution level.
                    </p>
                </div>

                <div className = 'hidden md:block'>
                    <img src = {ratingsImage} alt = "ratings" />
                </div>
            </section>
        </Ratings>
    )
}

const Ratings = styled.div`
    margin-top: 8.36rem;
    margin-bottom: 6.12rem;
    background: #FFF8FA;
    padding-top: 9.87rem;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 4rem;

    h1 {
        font-family: "GreyCliff-Bold", sans-serif;
        font-size: 2.125rem;
        line-height: 2.67rem;
        color: #003168;
    }

    p {
        font-size: 1.125rem;
        line-height: 1.65rem;
        font-family: "GreyClif-Regular", sans-serif;
        max-width: 45.87rem;
    }

    @media screen and (max-width: 650px) {
        padding: 2.81rem 1.25rem; 
        margin-top: 3.12rem;
        margin-bottom: 3.12rem;
        p {
            max-width: none;
        }
    }

    @media screen and (max-width: 2000px) and (min-width: 1400px) {
        padding-left: 11rem;
        padding-right: 11rem;

        p {
            max-width: 50rem;
        }
    }
`

export default ratings
