import React from 'react'
import styled from 'styled-components'
import RibbonAppImage from '../../asset/desktop/Ribbon App.png'
import Line from '../../asset/desktop/line.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const Incentives = () => {
    return (
        <Incent className = 'py-16 sm:py-32 grid sm:grid-cols-2 grid-cols-1 sm:my-10' data-aos = 'flip-left'>
            <section className = 'sm:ml-20'>
                <div>
                    <img src = {RibbonAppImage} alt = 'ribbon' />
                </div>
            </section> 
            <section className = 'sm:flex flex-col justify-center px-5 sm:px-0 sm:ml-10'>
                <div className = 'mb-2'>
                    <img src = {Line} alt = "line" />
                </div>
                <p className = 'mb-3 text'>Ribbon Incentives App</p>
                <div>
                    <p className = 'sm:max-w-xl subtext text-base sm:text-lg'>
                        The Ribbon Incentives App is the tool used by Community Health Workers, 
                        Practitioners, Patients and Individuals to capture and digitize personal 
                        health data while earning Tokenized Incentives for every set of collected data. 
                        The Ribbon Incentives App also enables users to access DeFi financial services 
                        and loans using their personal health data as a collateral asset to back loans.
                    </p>
                    
                    <div className = 'btn-container'>
                        <a href = '/' className = 'text-white py-3 px-7'>Explore DeFi</a>
                    </div>
                </div>
            </section> 
        </Incent>
    )
}

const Incent = styled.div`
    background: #F0F7FF;

    .text {
        color: #041F3C;
        font-family: "Greycliff-Bold", sans-serif;
        font-size: 2.125rem;
    }

    .subtext {
        color: #333333;
        font-family: "Greycliff-Regular", sans-serif;
        font-size: 1.125rem;
    }

    .btn-container {
        margin-top: 2.5rem;
        a {
            font-family: "Greycliff-DemiBold", sans-serif;
        }
    }
    
    .btn-container a {
        background: #FF6495;
        font-size: 1em;
        box-shadow: -5px 5px #98C3FF;
        border-radius: 10px;
    }

    @media screen and (max-width: 650px) {
        background: none;

        .text {
            font-size: 1.25rem;
        }

        .subtext {
            font-size: 1rem;
        }
    }

`

export default Incentives
