import React from 'react'
import styled from 'styled-components'
import line from '../../asset/desktop/line.png'
import HealthCard from './healthCard'
import collection from '../../asset/desktop/collection.png';
import analytics from '../../asset/desktop/analytics.png';
import monetize from '../../asset/desktop/monetize.png';
import interactions from '../../asset/desktop/interactions.png';
import rating from '../../asset/desktop/rating.png'
import group from '../../asset/desktop/Group.png'


const health = ({ headerText, body1, body2, body3 }) => {
    return (
        <Health>
            <section className='text-white first px-4'>
                <div className='content'>
                    {headerText &&
                        <>
                            <img src={line} alt='line' className='mb-3' />
                            <h1 className='mb-5'>
                                {headerText}
                            </h1>
                        </>
                    }

                    <div className='text'>
                        <p className='mb-3'>
                            {body1}
                        </p>

                        {
                            body2 &&
                            <p className='mb-3'>
                                {body2}
                            </p>
                        }


                        {body3 &&
                            <p>{body3}</p>
                        }
                    </div>
                </div>

                <div className = 'group'>
                    <img src = {group} alt = "group" className = 'object-fit' />
                </div>
            </section>

            <section className='grid grid-col-1 md:grid-cols-2 md:px-16 lg:px-28 sm:gap-20 px-4 gap-10 second'>
                <HealthCard
                    src={collection}
                    heading="Collection"
                    text="The Ribbon Incentives App is used as the tool for collecting personal and practice level information from Patients, Practitioners and Community Health Worker, where such information is incentivized."
                />

                <div className = 'md:justify-self-end'>
                    <HealthCard
                        src={analytics}
                        heading="Analytics"
                        text="The Ribbon Protocol will process data analytics and compile information into reports, publications and insights for the information demand markets."
                />
                </div>

                <HealthCard
                    src={monetize}
                    heading="Monetization"
                    text="The Ribbon Marketplace is the platform where all information will  be priced and traded as reports, insights or publications in the data marketplace.  "
                />

                <div className = 'md:justify-self-end'>
                    <HealthCard
                        src={interactions}
                        heading="Interactions"
                        text="We utilized skilled and trained community health workers as Care Partners to digitize all healthcare interactions between patients and healthcare practitioners to create data provenance that provides context and meaning to health data."
                    />
                </div>
            </section>
        </Health>
    )
}

const Health = styled.div`

    .first {
        padding-top: 10rem;
        padding-bottom: 17.56rem;
        position: relative;
        background: #161F6D;
        overflow: hidden;
        position: relative;

        .group {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;

            img {
                height: 100%;
                width: 100%;
            }
        }
        
        .content {
            max-width: 34.4rem;
            margin: 0 auto;
        }

        h1 {
            font-family: "GreyCliff-Bold", sans-serif;
            font-size: 2.125rem;
        }

        .text {
            font-size: 1.125rem;
            font-family: GreyCliff-Regular;
            line-height: 1.65rem;
        }

        z-index: -2;
    }

    .second {
        margin-top: -8.93rem;
        z-index: 10;
    }

    @media screen and (max-width: 650px) {
        .first {
            padding-top: 4.43rem;
            padding-bottom: 11.625rem;
            .content {
                max-width: none;
            }
        }

        .second {
            margin-top: -8.93rem;
            z-index: 10;
        }
    }
`

export default health
