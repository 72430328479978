import styled from 'styled-components'
import Landing from "../../asset/desktop/landing.png"
import Title from "../../components/common/Title";
import LazyLoad from 'react-lazyload';
import Gugu from "../../asset/desktop/people/gugu.jpeg"
import alabi from "../../asset/desktop/people/ibrahim.jpg"
import ayeola from "../../asset/desktop/people/ayeola.png"
import Ayomide from "../../asset/desktop/people/Ayomide.jpg"
import bamidelee from "../../asset/desktop/people/bamidele.jpeg"
import Marvel from "../../asset/desktop/people/marvel.jpeg"
import Maureen from "../../asset/desktop/people/Maureen.jpeg"
import Rasheedat from "../../asset/desktop/people/rash.JPG"  
import Ruth from "../../asset/desktop/people/ruth.jpeg"
import Victor from "../../asset/desktop/people/victor.jpeg"
import Labade from "../../asset/desktop/people/labade.jpeg"
import Miracle from "../../asset/desktop/people/miracle.jpeg"
import ISN from "../../asset/desktop/people/isn.JPG"
import { IoLogoLinkedin } from 'react-icons/io'

const BlueOverlay = styled.div`
  background: url(${Landing});
  height: 452px;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .overlay{
    background-color: rgba(24, 33, 116, 0.89);
    z-index: 2;
  }
  `;

const Holder = styled.div`
  img.user{
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  h1{
    font-size: 1.5rem ;
    font-weight: bold;
    color: #333333;
    margin: .5rem 0;
  }
`;

const People = () => {

  const allDetails = [
    {
      img: Gugu,
      name: "Gugulethu",
      title: "Founder",
      linkedIn: 'https://www.linkedin.com/in/gugu-newman-nyathi-11ab17a7'
    },
    // {
    //   img: mpadi,
    //   name: "Mpadi Makgalo",
    //   title: "Public Health",
    //   linkedin: "",
    // },
    // { 
    //   img: benonuoha,
    //   name: "Ben Onuoha",
    //   title: "Blockchain Strategy",
    // },
    // {
    //   img: chris,
    //   name: "Chris Maree",
    //   title: "Tech Advisory",
    //   linkedin: "",
    // },
    {
      img: bamidelee,
      name: "Bamidele",
      title: "Tech Advisory",
      linkedIn: "https://www.linkedin.com/in/bamidelealegbe/"
    },
    {
      img: Maureen,
      name: "Maureen",
      title: "Human Relations",
      linkedIn: "https://www.linkedin.com/in/eziaku-omeoga-acipm-a52127100"
    },
    {
      img: alabi,
      name: "Alabi",
      title: "Software Tester",
      linkedIn: "https://www.linkedin.com/in/alabi-ibrahim-7437a518a"
    },
    {
      img: Ruth,
      name: "Ruth",
      title: "UI/UX Designer",
      linkedIn: 'https://www.linkedin.com/in/ruthdouglasidaneme'
    },
    {
      img: Rasheedat,
      name: "Rasheedat",
      title: "UI/UX Designer",
      linkedIn: 'https://www.linkedin.com/in/rasheedat-usman-158736174'
    },
    {
      img: Ayomide,
      name: "Ayomide",
      title: "Software Developer",
      linkedIn: 'https://www.linkedin.com/in/ayomide-peter-6440021b0'
    },
    {
      img: ayeola,
      name: "Ayeola",
      title: "Software Developer",
      linkedIn: "https://www.linkedin.com/in/ayeolakenny"
    },
    {
      img: Marvel,
      name: "Marvel",
      title: "Software Developer",
      linkedIn: "https://www.linkedin.com/in/marvelous-afolabi-22a290183"
    },
    {
      img: Victor,
      name: "Victor",
      title: "Software Developer",
      linkedIn: 'https://www.linkedin.com/in/arpeiks'
    },

    {
      img: Labade,
      name: "Labade",
      title: "Community Manager",
      linkedIn: "https://www.linkedin.com/in/labade-victor-0ab429229"
    },

    {
      img: ISN,
      name: "ISN",
      title: "Social Media Manager",
      linkedIn: "https://www.linkedin.com/in/samuel-isaiah-77449a102"
    },

    {
      img: Miracle,
      name: "Miracle",
      title: "Graphics Designer",
      linkedIn: "https://www.linkedin.com/in/miracledre/"
    },
  ]
  return (
    <section className = 'mt-6'>
      <BlueOverlay >
        <div className="overlay absolute top-0 right-0 w-full h-full"></div>
        <div className="container mx-auto px-5 z-10">
          <Title text='People' bgc='#000B6A' textColor="white" />
        </div>
      </BlueOverlay>


      <section className="-mt-32 mb-16 px-5 lg:px-32 z-30 relative grid md:grid-cols-2 lg:grid-cols-3 lg:gap-y-12 gap-10 container mx-auto">
        {
          allDetails.map((person, i) => (
            <Holder className="w-full mx-auto" key = {i}>
              <LazyLoad once>
                <img src={person.img} alt="" className="user rounded-lg" />
              </LazyLoad>
              <h1 >{person.name}</h1>
              <div className="flex items-center justify-between">
                <p className="font-medium text-lg">{person.title}</p>
              </div>
              <a href={person.linkedIn}>
                <IoLogoLinkedin size = '28' color = "blue-500" />
              </a>
            </Holder>
          ))
        }

      </section>

    </section>
  )
}

export default People
