import styled from "styled-components";

// responsiveness

export const Gov = styled.section`

    overflow-y:hidden;

    @media screen and (max-width: 340px) {
        .hey {
            padding-bottom: 11rem;
        }
    }

    @media screen and (max-width: 315px) {
        .hey {
            padding-bottom: 15rem;
        }
    }

    @media screen and (max-width: 300px) {
        .hey {
            padding-bottom: 30rem;
        }
    }
`;
