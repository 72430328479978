import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import useScrollListener from "../common/listenScroll";
import { Divide as Hamburger } from "hamburger-react";
import styled from "styled-components";
import Logo from "../../asset/logo.png";
import whitepaper from "../../asset/desktop/checklist.png";
import styles from "./styles.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import RibbonWhitePaper from "../../asset/RibbonProtocolWhitepaper.pdf";

/* eslint-disable */

import { aboutData, docsData, tokenData } from "../data";
import { Listbox, Transition } from "@headlessui/react";

const Home = ({ menu, setMenu }) => {
  const handleToggle = () => {
    setMenu((prevState) => {
      return !prevState;
    });
  };

  const [navClassList, setNavClassList] = useState([]);
  const [selectedAboutValue, setSelectedAboutValue] = useState(aboutData[1]);
  const [selectedDocsValue, setSelectedDocsValue] = useState(docsData[0]);
  const [selectedTokenValue, setSelectedTokenValue] = useState(tokenData[0]);

  const scroll = useScrollListener();

  useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  return (
    <Header className={`${navClassList.join(" ")}`}>
      <nav className="w-full">
        <div className="flex items-center ham-cont justify-between sm:px-10  px-5">
          <div>
            <img src={Logo} alt="logo" />
          </div>

          <ul className="hidden sm:flex gap-x-16">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>

            <li>
              <Listbox
                as="div"
                value={selectedAboutValue}
                onChange={setSelectedAboutValue}
                className="relative"
              >
                <Listbox.Button>
                  <span className="flex items-center gap-x-2 cursor-pointer">
                    About
                    <MdKeyboardArrowDown />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-2 shadow-xl bg-white py-2 pl-2 pr-6 grid gap-y-4">
                    {aboutData.map((data, i) => (
                      <NavLink to={data.to} key={i}>
                        <Listbox.Option>{data.text}</Listbox.Option>
                      </NavLink>
                    ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            </li>

            <li>
              <Listbox
                as="div"
                value={selectedDocsValue}
                onChange={setSelectedDocsValue}
                className="relative"
              >
                <Listbox.Button>
                  <span className="flex items-center gap-x-2 cursor-pointer">
                    Docs
                    <MdKeyboardArrowDown />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-2 shadow-xl bg-white py-2 pl-2 pr-6 grid gap-y-4">
                    {docsData.map((data, i) => (
                      <NavLink to={data.to} key={i}>
                        <Listbox.Option value={data}>
                          {data.text}
                        </Listbox.Option>
                      </NavLink>
                    ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            </li>

            <li>
              <Listbox
                as="div"
                value={selectedTokenValue}
                onChange={setSelectedTokenValue}
                className="relative"
              >
                <Listbox.Button>
                  <span className="flex items-center gap-x-2 cursor-pointer">
                    <NavLink to="/token">Token</NavLink>
                    <MdKeyboardArrowDown />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-2 shadow-xl bg-white py-2 pl-2 pr-6 grid gap-y-4">
                    {tokenData.map((data, i) => (
                      <NavLink to={data.to} key={i}>
                        <Listbox.Option value={data}>
                          {data.text}
                        </Listbox.Option>
                      </NavLink>
                    ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            </li>

            {/* <li>
              <NavLink to="/help">FAQ</NavLink>
            </li> */}

            {/* <li>
              <NavLink to="/token">Token</NavLink>
            </li> */}
          </ul>

          <div className="whitepaper text-white hidden sm:block">
            <button
              className="py-1 flex items-center px-2 font-semibold text-lg"
              type="submit"
            >
              <img src={whitepaper} alt="whitepaper" className="inline-block" />
              <a href={RibbonWhitePaper} download>
                Whitepaper
              </a>
            </button>
          </div>

          <div className="block sm:hidden ham">
            <Hamburger onToggle={handleToggle} toggled={menu} />
          </div>
        </div>

        {menu && (
          <div className="mobile sm:hidden">
            <ul className="sidebar grid gap-y-6">
              <li className="mt-20">
                <NavLink
                  to="/"
                  exact={true}
                  onClick={handleToggle}
                  className="w-full pl-6"
                >
                  Home
                </NavLink>
              </li>

              <li>
                <Listbox
                  as="div"
                  value={selectedAboutValue}
                  onChange={setSelectedAboutValue}
                  className="pl-6"
                >
                  <Listbox.Button>
                    <span className="flex w-20 justify-between items-center gap-x-2 cursor-pointer">
                      About
                      <MdKeyboardArrowDown />
                    </span>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="mt-2 py-2 pl-2 pr-6 grid gap-y-4">
                      {aboutData.map((data, i) => (
                        <NavLink to={data.to} key={i}>
                          <Listbox.Option onClick={handleToggle}>
                            {data.text}
                          </Listbox.Option>
                        </NavLink>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </Listbox>
              </li>

              <li>
                <Listbox
                  as="div"
                  value={selectedDocsValue}
                  onChange={setSelectedDocsValue}
                  className="pl-6"
                >
                  <Listbox.Button>
                    <span className="flex w-20 justify-between items-center gap-x-2 cursor-pointer">
                      Docs
                      <MdKeyboardArrowDown />
                    </span>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="mt-2 py-2 pl-2 pr-6 grid gap-y-4">
                      {docsData.map((data, i) => (
                        <NavLink to={data.to} key={i}>
                          <Listbox.Option value={data} onClick={handleToggle}>
                            {data.text}
                          </Listbox.Option>
                        </NavLink>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </Listbox>
              </li>

              <li>
                <Listbox
                  as="div"
                  value={selectedTokenValue}
                  onChange={setSelectedTokenValue}
                  className="pl-6"
                >
                  <Listbox.Button>
                    <span className="flex w-20 justify-between items-center gap-x-2 cursor-pointer">
                      <NavLink to="/token" onClick={handleToggle}>Token</NavLink>
                      <MdKeyboardArrowDown />
                    </span>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="mt-2 py-2 pl-2 pr-6 grid gap-y-4">
                      {tokenData.map((data, i) => (
                        <NavLink to={data.to} key={i}>
                          <Listbox.Option onClick={handleToggle}>
                            {data.text}
                          </Listbox.Option>
                        </NavLink>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </Listbox>
              </li>

              {/* <li>
                <NavLink
                  className="w-full pl-6"
                  to="/help"
                  onClick={handleToggle}
                >
                  FAQ
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink
                  className="w-full pl-6"
                  to="/token"
                  onClick={handleToggle}
                >
                  Token
                </NavLink>
              </li> */}

              <div className="whitepaper text-white text-center mt-10 pl-4">
                <button
                  className="font-semibold flex items-center py-2 px-3 text-lg"
                  type="submit"
                >
                  <img
                    src={whitepaper}
                    alt="whitepaper"
                    className="inline-block"
                  />
                  <a href={RibbonWhitePaper} download>
                    Whitepapers
                  </a>
                </button>
              </div>
            </ul>
          </div>
        )}
      </nav>
    </Header>
  );
};
const Header = styled.nav`
  font-family: "GreyCliff-DemiBold", sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 1000000000;
  transition: transform 150ms ease-in-out;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);

  .whitepaper {
    cursor: pointer;
    button {
      border-radius: 5px;
      box-shadow: 0 0 10px rgb(170, 170, 170);
      background: #ff6495;
    }
  }

  .whitepaper img {
    height: 15px;
  }

  .mobile {
    z-index: 100;
  }

  .mobile {
    animation-name: scroll-down;
    animation-duration: 0.5s;
    /* height: 93vh; */

    ul {
      background: none;
    }

    li {
      font-weight: 600;
      font-size: 1rem;
      /* margin-left: 1.5rem; */

      a {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      a.active {
        background: #fff8fa;
      }
    }

    @keyframes scroll-down {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(0%);
      }
    }
  }

  .ham-cont {
    z-index: 1000;
    position: relative;
  }

  .mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .mobile {
    height: 100vh;
    background: #fff;
  }
`;

export default Home;
