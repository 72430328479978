import React from "react";
import Hero from '../../components/Home/Hero'
import Ribbon from '../../components/Home/Ribbon'
import Question from '../../components/Home/How'
import Defi from "../../components/Home/Defi"
import Incentives from '../../components/Home/Incentives'

const Home = () => {
  return (
    <div style={{overflow: 'hidden'}}>
      <Hero />
      <Ribbon />
      <Question />
      <Defi />
      <Incentives />
    </div>
  )
}

export default Home
