const generalQuestion = [
  {
    question: "Will there be a Ribbon Airdrop?",
    answer:
      "There will be a Ribbon token Airdrop to mobilize user adoption and incentivize team and community contribution. Furthermore, members of the Ribbon Protocol community can earn behavioral incentive Ribbon Tokens through the Ribbon Health Wallet App and through participating in ecosystem development and work activities on our Discord, Forums, Events and Github. Further information will be shared in due course on our Medium blog and Social Media accounts.",
  },

  {
    question: "Is the team legit?",
    answer:
      "The current Ribbon Protocol team is fully Doxxed and will become a DAO (Decentralized Autonomous Organization) as the project grows, which means that the team will consist of both Doxxed and Anonymous community members. Anyone can join the Ribbon Protocol community, helping it grow and proposing ideas / collaborations of all kinds.",
  },

  {
    question: "What is a DAO?",
    answer:
      "Decentralized Autonomous Organizations are coordination protocols that enable humans to work together towards a common goal by regulating interactions through rules defined in software. DAO's are unique because they put automation at the center and humans at the edges. There is no CEO, nor managers. Administration, where it must exist, is viewed as a design flaw and ruthlessly minimized.While centralized administration is a powerful and flexible means to scale human coordination, the advent of decentralized ledgers like Bitcoin and Ethereum have shown how disruptive, powerful, and resilient sufficiently decentralized coordination protocols can be. DAOs do not replace companies. Instead they alter the economic landscape by creating novel markets and opportunities in which individuals and companies can participate.In the case of the Ribbon Protocol DAO, a community currency called Ribbon Tokens is used to create a circular economy where public goods and investments that grow the Ribbon Protocol economy can be collectively funded through a vote.",
  },

  {
    question: "Can I stake Ribbon Tokens?",
    answer:
      "Staking will be available in due course after the and Airdrop distribution. The Airdrop tokens for the Ribbon Health Wallet will automatically be staked and unlocked through user adoption, and this process could take up to 2 or more years.",
  },

  {
    question: "What is the APY for staking Ribbon Tokens?",
    answer:
      "At the moment we cannot give an estimation of the APY but it will be shared in due course. Due to the long nature of public health programs, some up to 20 or more years, we anticipate short, medium and long term staking options for up to 20 years and more, all paying out different APY.   Staking rewards will be distributed monthly.",
  },

  {
    question: "Is there any incentive for holding Ribbon Tokens?",
    answer:
      "Holding at least 0.5 RBN allows you to participate in and, besides that, holding Ribbon s primarily used for speculative investment, staking, farming and to provide liquid.",
  },

  {
    question: "When will Ribbon Token be on Uniswap?",
    answer:
      "The Ribbon Token will be on Ethereum Mainnet, Polygon and Binance SmartChain on token generation and the Contract Addresses will be provided on the announcement.",
  },

  {
    question:
      "I saw a 'Polygon' and 'Roll-Ups' channel on Discord. Are we leaving xDai?",
    answer:
      "Our original design was on xDai, but we will migrate to Polygon for a commercially viable chain.with a bigger active developer support community . We are also exploring what opportunities may exist to extend the community and ecosystem of products to additional networks including roll-up chains such as Arbitrum. Optimism and more.",
  },

  {
    question: "Does 'voting on a proposal' actually cost Ribbon Tokens?",
    answer:
      "No, it doesn't. You are not spending your Ribbon Tokens when voting. Your Ribbon Tokens are not locked either, withdraw your vote and trade if you wish, but you do need a minimum 0.5 Ribbon to be eligible to vote.",
  },

  {
    question: "How can I contribute?",
    answer:
      "If you wish to contribute, make sure to check our Discord and Telegrams for more information.",
  },
];

const stakingAndFarming = [
  {
    question: "When will Farms open again?",
    answer:
      "Farming will be made available sometime after Airdrop, and when the farming strategies and contracts have been announced. We will keep you updated.",
  },
  {
    question: "How can I claim my Ribbon Token Airdrop?",
    answer:
      "When the Airdrop is live, all users that sign up to the Ribbon Health App and complete incentivized tasks will be eligible for an Airdrop, and will receive the Ribbon Tokens (on Polygon). Partners, investors, traders and other community members will be able to claim immediately on Ethereum Mainnet via the Ribbon Protocol’s Airdrop page, subject to a vesting lock schedule. All details will be announced in due course.",
  },
  {
    question: "Is there a tutorial on how to farm?",
    answer: "Video tutorials will be posted on our Youtube Channels.",
  },
  {
    question: "Will farming pairs change?",
    answer: "It is possible that new pairs will be added in the future.",
  },
];

const tokenomics = [
  {
    question:
      "What is the Ribbon token address? (Ethereum, Polygon, Binance Smart Chain)?",
    answer:
      "The Ribbon Token addresses for all chains will be provided in the Airdrop announcement.",
  },

  {
    question:
      "​What is the common pool address and where can the token transfers be viewed?",
    answer:
      "The token contract will be announced once the token is minted and airdropped.",
  },

  {
    question: "Why Ribbon?",
    answer:
      "Issuing a digital currency allows a community like Ribbon Protocol to build an ecosystem economy. Holding a specific community currency is a bet on the future growth of that community's economy. The more people who hold the currency, the more people who will have interest in participating and growing the economy. Each community may adopt different social norms and may use different issuance and distribution policies to allocate shared resources. The most effective communities will thrive and grow, competing for the attention and loyalty of individuals. Choosing to hold a specific currency will evolve beyond just a financial decision into a political and social one as well. This type of competition is good for everyone, and should hopefully lead to a future that is more free, fair, open, and humane.",
  },

  {
    question: "What is the current market cap of Ribbon?",
    answer:
      "The Market cap will be found on CoinGeck and Coinmarketcap and other listings once the token is trading.       ",
  },

  {
    question: "How is Ribbon's market cap calculated?",
    answer: "Market cap = (Total Supply - Common Pool) x Ribbon Price",
  },

  {
    question: "What is Ribbon's current Issuance Policy?",
    answer:
      "A dynamic inflation rate will be developed and integrated to align with the vesting schedule and user adoption rate in the Ribbon Wallet airdrop.",
  },

  {
    question: "Where does the newly inflated currency go?",
    answer:
      "The inflation rate will be used to fill the Common Pool, which holds the Ribbon the DAO has available to use and can only be withdrawn from by passing Ribbon Protocol proposals. The common pool is used to fund projects, bounties and collaboration with other projects that may happen in the future.",
  },

  {
    question: "Where can I see details about Honey?",
    answer:
      "Etherscan, Polygonscan and BSCscan is where you can track any transactions on each of the chains.",
  },
];

const support = [
  {
    question: "I am new in the community, what should I do?",
    answer:
      "First of all, welcome! Be sure to follow us on Telegram, Twitter, and engage in our Discord to get more information.",
  },
  {
    question: "My issue was not addressed in the FAQ. What can I do?",
    answer:
      "The best way to get help from a member of the Ribbon Protocol community is to land on the Help channel in our Discord.",
  },
];

export default function Help() {
  return (
    <section className="mt-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-x-6 px-4 md:px-16 text-base md:text-lg text-[#4D4D4D]">
        <div className="col-span-1">
            <p className="bg-[#F9FAFF] md:p-12 font-gcb hidden md:block">FAQ</p>
        </div>

        <div className="col-span-3">
          <div className="mb-8">
            <h3 className="text-[#000852] text-xl md:text-3xl font-gcb">
              General Question
            </h3>

            <hr className="my-2 md:my-4 bg-[#EBEBEB]" />

            <div className="">
              {generalQuestion.map((question, i) => (
                <div key={i} className="grid mb-2">
                  <h3 className="text-[#8A8FB6] text-lg md:text-xl">
                    {question.question}
                  </h3>
                  <p className="text-base md:text-lg">{question.answer}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <h3 className="text-[#000852] text-xl md:text-3xl font-gcb">
              Staking / Farming
            </h3>

            <hr className="my-2 md:my-4 bg-[#EBEBEB]" />

            <div>
              {stakingAndFarming.map((item, i) => (
                <div key={i} className="grid mb-2">
                  <h3 className="text-[#8A8FB6] text-lg md:text-xl">
                    {item.question}
                  </h3>
                  <p className="text-base md:text-lg">{item.answer}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <h3 className="text-[#000852] text-xl md:text-3xl font-gcb">
              Tokenomics
            </h3>

            <hr className="my-2 md:my-4 bg-[#EBEBEB]" />

            <div>
              {tokenomics.map((item, i) => (
                <div key={i} className="grid mb-2">
                  <h3 className="text-[#8A8FB6] text-lg md:text-xl">
                    {item.question}
                  </h3>
                  <p className="text-base md:text-lg">{item.answer}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <h3 className="text-[#000852] text-xl md:text-3xl font-gcb">
              Support
            </h3>

            <hr className="my-2 md:my-4 bg-[#EBEBEB]" />

            <div>
              {support.map((item, i) => (
                <div key={i} className="grid mb-2">
                  <h3 className="text-[#8A8FB6] text-lg md:text-xl">
                    {item.question}
                  </h3>
                  <p className="text-base md:text-lg">{item.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
