import Patient from "../../asset/desktop/characters/patient.png";
import HealthPractitioner from "../../asset/desktop/characters/healthcare.png";
import CHW from "../../asset/desktop/characters/chw.png";
import Healer from "../../asset/desktop/characters/healer.png";
import MobilePatient from "../../asset/mobile/characters/patient.png";
import MobilePreactitioner from "../../asset/mobile/characters/practitioner.png";
import MobileCHW from "../../asset/mobile/characters/chw.png";
import MobileHealer from "../../asset/mobile/characters/healer.png";
import LazyLoad from "react-lazyload";

const characters = [
  {
    name: "Patient",
    desktopPicture: Patient,
    mobilePicture: MobilePatient,
    text: "This character is generally the default character for the system, and includes healthy, sick, chronic patients. Some Patients users can also have more than one profile in the game if they work in healthcare roles that fall under the other playable characters,",
    bg: "#FF6495",
  },

  {
    name: "Healthcare Practitioner",
    desktopPicture: HealthPractitioner,
    mobilePicture: MobilePreactitioner,
    text: "This character is generally for verified and trained Health Workers that work independently or in organizations and care providers, including Doctors, Nurses, Specialists and more",
    bg: "#65C1F6",
  },
  {
    name: "Community Health Worker",
    desktopPicture: CHW,
    mobilePicture: MobileCHW,
    text: "This character is generally for verified and trained Community Health Workers that work independently or in organizations and care providers.",
    bg: "#FF6495",
  },
  {
    name: "Healer",
    desktopPicture: Healer,
    mobilePicture: MobileHealer,
    text: "This character is generally for individuals that practice as Healers, including Sages, Shamans, Prophets, Spiritual Healers, Sangomas, WitchDoctors and more",
    bg: "red-500",
  },
];

export default function Character() {
  return (
    <div className="px-4 md:px-20 xl:px-40">
      <h3 className="font-gcb text-[#003168] text-2xl text-center md:text-[2.5rem] py-[3.75rem] md:py-16">
        Characters
      </h3>

      <section className="grid gap-y-8">
        <div className="md:flex justify-between items-center mb-6">
          <div className="mb-12 md:mb-0 bg-[#FFF8FA] md:w-[465px] md:h-[451px] flex justify-center items-center rounded-[20px] border border-[#FF6495]">
            <LazyLoad once>
              <img
                src={characters[0].desktopPicture}
                alt="characters"
                className="object-cover hidden md:block"
              />
              <img
                src={characters[0].mobilePicture}
                alt="characters"
                className="object-cover block md:hidden"
              />
            </LazyLoad>
          </div>

          <div className="relative">
            <h2 className="text-[#003168] text-xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
              {characters[0].name}
            </h2>

            <p className="text-[#333333] text-base font-gcr max-w-[30rem]">
              {characters[0].text}
            </p>
          </div>
        </div>

        <div className="md:flex flex-row-reverse justify-between items-center mb-6">
          <div className="mb-12 md:mb-0 bg-[#F0F7FF] md:w-[465px] md:h-[451px] flex justify-center items-center rounded-[20px] border border-[#65C1F6]">
            <LazyLoad once>
              <img
                src={characters[1].desktopPicture}
                alt="characters"
                className="object-cover hidden md:block"
              />
              <img
                src={characters[1].mobilePicture}
                alt="characters"
                className="object-cover block md:hidden"
              />
            </LazyLoad>
          </div>

          <div className="relative">
            <h2 className="text-[#003168] text-xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
              {characters[1].name}
            </h2>

            <p className="text-[#333333] text-base font-gcr max-w-[30rem]">
              {characters[1].text}
            </p>
          </div>
        </div>

        <div className="md:flex justify-between items-center mb-6">
          <div className="mb-12 md:mb-0 bg-[#FFF8FA] md:w-[465px] md:h-[451px] flex justify-center items-center rounded-[20px] border border-[#FF6495]">
            <LazyLoad once>
              <img
                src={characters[2].desktopPicture}
                alt="characters"
                className="object-cover hidden md:block"
              />

              <img
                src={characters[2].desktopPicture}
                alt="characters"
                className="object-cover block md:hidden"
              />
            </LazyLoad>
            
          </div>

          <div className="relative">
            <h2 className="text-[#003168] text-xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
              {characters[2].name}
            </h2>

            <p className="text-[#333333] text-base font-gcr max-w-[30rem]">
              {characters[2].text}
            </p>
          </div>
        </div>

        <div className="md:flex flex-row-reverse justify-between items-center mb-6">
          <div className="mb-12 md:mb-0 bg-[#F0F7FF] md:w-[465px] md:h-[451px] flex justify-center items-center rounded-[20px] border border-[#65C1F6]">
            <LazyLoad once>
              <img
                src={characters[3].desktopPicture}
                alt="characters"
                className="object-cover hidden md:block"
              />

              <img
                src={characters[3].desktopPicture}
                alt="characters"
                className="object-cover block md:hidden"
              />
            </LazyLoad>
          </div>

          <div className="relative">
            <h2 className="text-[#003168] text-xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
              {characters[3].name}
            </h2>

            <p className="text-[#333333] text-base font-gcr max-w-[30rem]">
              {characters[3].text}
            </p>
          </div>
        </div>

        {/* {characters.map((character, i) => (
          <div key={i} className = "grid grid-cols-1 md:grid-cols-2 items-center">
            <div className={`mb-6`} >
              <LazyLoad once>
                <img src={character.desktopPicture} alt="characters" className={`bg-[#FF6495]`} />
              </LazyLoad>
            </div>

            <div className="relative">
              <h2 className="text-[#003168] text-xl font-gcb mb-5 after:content-[''] after:absolute after:left-0 after:top-0 after:w-[80px] after:h-[5px] after:-mt-5 after:bg-[#ff6495]">
                {character.name}
              </h2>

              <p className="text-[#333333] text-base font-gcr">
              {character.text}
            </p>
            </div>
          </div>
        ))} */}
      </section>
    </div>
  );
}
