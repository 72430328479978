import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Home from "./pages/Home";
import Incentives from "./pages/Incentives";
import Protocol from "./pages/Protocol";
import Governance from "./pages/Governance";
import Information from "./pages/Information";
import DeFi from "./pages/DeFi";
import Web from "./pages/Web";
import People from "./pages/People";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Help from "./pages/Help";
import ScrollToTop from "./components/scrollToTop";
import Tokenomics from "./pages/Tokenomics";
import Games from "./pages/Games";
import Token from "./pages/Token";
import TandC from "./pages/TandC";

function App() {
  const [menu, setMenu] = useState(false);

  return (
    <Div className="App" hide={menu}>
      <BrowserRouter>
        <div className="mt-12">
          <Header menu={menu} setMenu={setMenu} />
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/token" element={<Token />} />

          <Route path="/about">
            <Route path="protocol" element={<Protocol />} />
            <Route path="incentives" element={<Incentives />} />
            <Route path="technology" element={<Web />} />
            <Route path="information" element={<Information />} />
            <Route path="people" element={<People />} />
            <Route path="games" element={<Games />} />
            <Route path="tokenomics" element={<Tokenomics />} />
          </Route>

          <Route path="/docs">
            <Route path="governance" element={<Governance />} />
            <Route path="De-Fi" element={<DeFi />} />
          </Route>

          <Route path="/token">
            <Route path="help" element={<Help />} />
            <Route path="t&c" element={<TandC />} />
          </Route>
        </Routes>

        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </Div>
  );
}

const Div = styled.div`
  height: ${(props) => (props.hide ? "100vh" : "auto")};
  overflow-y: ${(props) => (props.hide ? "hidden" : "auto")};
  /* margin-top: ${(props) => (props.hide ? "0px" : "100px")}; */
  /* margin-top: 100px; */
  margin-top: 40px;

  @media screen and (max-width: 650px) {
    /* margin-top: ${(props) => (props.hide ? "0px" : "75px")}; */
    margin-top: 20px;
  }
`;

export default App;
