
import styled from 'styled-components'
import line from '../../asset/desktop/line.png'
import HealthCard from '../../components/Information/healthCard'

const Cards = ({ headerText, body1, body2, body3, cardsArray }) => {

  return (
    <Health>
      <section className='text-white first px-4'>
        <div className='content'>
          {headerText &&
            <>
              <img src={line} alt='line' className='mb-3' />
              <h1 className='mb-5'>
                {headerText}
              </h1>
            </>
          }

          <div className='text'>
            <p className='mb-3'>
              {body1}
            </p>

            {
              body2 &&
              <p className='mb-3'>
                {body2}
              </p>
            }


            {body3 &&
              <p>{body3}</p>
            }
          </div>
        </div>
      </section>

      {
        cardsArray &&
        <section className='grid grid-col-1 md:grid-cols-2 md:px-16 lg:px-28 sm:gap-20 px-4 gap-y-10 second md:place-items-center'>
          {
            cardsArray.map((item, i) => (
              <HealthCard
                key = {i}
                src={item.img}
                heading={item.heading}
                text={item.text}
              />
            ))
          }
        </section>
      }
    </Health>
  )
}

const Health = styled.div`

    .first {
        padding-top: 10rem;
        padding-bottom: 17.56rem;
        position: relative;
        background: #161F6D;
        overflow: hidden;
        
        .content {
            max-width: 34.4rem;
            margin: 0 auto;
        }

        h1 {
            font-family: "GreyCliff-Bold", sans-serif;
            font-size: 2.125rem;
        }

        .text {
            font-size: 1.125rem;
            font-family: GreyCliff-Regular;
            line-height: 1.65rem;
        }

        z-index: -2;
    }

    .second {
        margin-top: -8.93rem;
        z-index: 10;
    }

    @media screen and (max-width: 650px) {
        .first {
            padding-top: 4.43rem;
            padding-bottom: 11.625rem;
            .content {
                max-width: none;
            }
        }

        .second {
            margin-top: -8.93rem;
            z-index: 10;
        }
    }
`

export default Cards
