
import { createGlobalStyle } from 'styled-components'

import GreyCliffTtf from './asset/fonts/GreycliffCF-Regular.ttf'
import GreyCliffWoff from './asset/fonts/GreycliffCF-Regular.woff'
import GreyCliffBoldTtf from './asset/fonts/GreycliffCF-Bold.ttf'
import GreyCliffBoldWoff from './asset/fonts/GreycliffCF-Bold.woff'
import GreyCliffDemiBoldTtf from './asset/fonts/GreycliffCF-DemiBold.ttf'
import GreyCliffDemiBoldWoff from './asset/fonts/GreycliffCF-DemiBold.woff'
import GreyCliffLightTtf from './asset/fonts/GreycliffCF-Light.ttf'
import GreyCliffLightWoff from './asset/fonts/GreycliffCF-Light.woff'



const FontStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');

    @font-face {
        font-family: "Greycliff-Regular";
        font-display: swap;
        src: local('Greycliff-Regular'), url(${GreyCliffTtf}) format('truetype'),
            url(${GreyCliffWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Greycliff-Bold";
        font-display: swap;
        src: local('Greycliff-Bold'), url(${GreyCliffBoldTtf}) format('truetype'),
            url(${GreyCliffBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Greycliff-DemiBold";
        font-display: swap;
        src: local('Greycliff-DemiBold'), url(${GreyCliffDemiBoldTtf}) format('truetype'),
            url(${GreyCliffDemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Greycliff-Light";
        font-display: swap;
        src: local('Greycliff-Light'), url(${GreyCliffLightTtf}) format('truetype'),
            url(${GreyCliffLightWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
`

export default FontStyles ;