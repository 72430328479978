import Title from "../../components/common/Title";
import CHWImg from '../../asset/desktop/Web/arti.png'
import PractitionersImg from '../../asset/desktop/Web/robot.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const Others = () => {
  return (
    <div>
      {/*  */}
      <div className='section_two bg-sec-pink mt-6 md:flex md:flex-row-reverse items-center md:justify-between md:py-16'>
        <div
          className='img_container w-full md:w-4/12 md:mr-32   mt-4 p-8'
          data-aos='slide-left'>
          <img src={CHWImg} alt='' className='w-full h-full' />
        </div>

        <div
          className='info text-left p-8 md:ml-20 md:mt-6  md:h-80 md:w-1/2'
          data-aos='slide-right'>
          <Title text='Artificial Intelligence' bgc='#fff' reg />
          <p className='text text-text-dark text-lg mt-10 sm:mt-0'>
            Artificial intelligence (AI) will be used for a variety of healthcare and research purposes, 
            including detection of disease, management of chronic conditions, delivery of health services,
             and drug discovery.
          </p>
        </div>
      </div>


      {/*  */}
      <div className='section_three bg-sec-blue md:py-20 md:flex md:justify-center md:items-center mt-8'>
        <div
          className='img_container w-full mt-4 p-8   md:w-80  flex justify-center items-center '
          data-aos='fade-right'>
          <img
            src={PractitionersImg}
            alt=''
            className='w-64 md:w-full h-full'
          />
        </div>

        <div
          className='info text-left p-8 text-lg md:w-1/2 md:ml-16  '
          data-aos='fade-left'>
          <Title text='Machine Learning' reg bgc='#fff' />
          <p className='text text-text-dark text-md md:leading-6'>
            The Ribbon Protocol’s analytics will rely on Machine Learning 
            abilities to process huge datasets beyond the scope of human capability, 
            and then reliably convert analysis of that data into clinical insights that aid 
            physicians in planning and providing care, ultimately leading to better health outcomes.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Others
