import React from 'react'
import styled from 'styled-components'
import DefiImage from '../../asset/desktop/DeFi.png'
import Line from '../../asset/desktop/line.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 1000,
});

const Defi = () => {
    return (
        <DeFi className = 'grid sm:grid-cols-2 grid-cols-1 py-10' data-aos = 'fade-in'>

            <section className = 'sm:order-last sm:place-self-end mb-10 sm:mb-0'>
                <div>
                    <img src = {DefiImage} alt = "defi" />
                </div>
            </section>

            <section className = 'sm:flex flex-col justify-center px-5 sm:px-0 sm:ml-20'>
                <div className = 'sm:mb-2'>
                    <img src = {Line} alt = "line" />
                </div>
                <p className = 'mb-3 text'>DEFI</p>
                <div>
                    <p className = 'font-normal subtext'>
                        Ribbon&apos;s DeFi platform enables Holders of Trillions 
                        worth of Cryptocurrencies to invest, provide liquidity, stake, 
                        lend and borrow digital assets securely, and earn ROI tied to 
                        the ever increasing commercial value of Tokenized global health 
                        data and information.
                    </p>
                    
                    <div className = 'btn-container'>
                        <a href = '/' className = 'text-white py-3 px-7'>Explore DeFi</a>
                    </div>
                </div>
            </section>
        </DeFi>
    )
}

const DeFi = styled.div`
    background: #FFF8FA;

    .text {
        color: #041F3C;
        font-size: 2.125rem;
        font-family: "Greycliff-Bold", sans-serif;
    }

    .subtext {
        color: #333333;
        max-width: 33.13rem;
        line-height: 1.65rem;
        font-size: 1.125rem;
        font-family: "Greycliff-Regular", sans-serif
    }


    .btn-container {
        margin-top: 2.5rem;

        a {
            font-family: "Greycliff-DemiBold", sans-serif;
        }
    }
    

    .btn-container a {
        background: #FF6495;
        font-size: 1em;
        box-shadow: -5px 5px #98C3FF;
        border-radius: 10px;
    }

    @media screen and (max-width: 650px) {
        .text {
            font-size: 1.25rem;
        }

        .subtext {
            font-size: 1rem;
        }
    }
`

export default Defi
