import styled from "styled-components";
import HeroImage from "../../../asset/desktop/hero.png";
import HeroImageMobile from "../../../asset/mobile/hero.png";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const HeroPage = () => {
  return (
    <Hero data-aos="flip-right">
      <section>
        <div className="img">
          <img
            src={HeroImage}
            alt="hero.png"
            className="w-full object-fit sm:block hidden"
          />
        </div>
        <div>
          <img
            src={HeroImageMobile}
            alt="hero.png"
            className="w-full sm:hidden block"
          />
        </div>
      </section>
      <section
        className="text-center sec-two sm:my-20 my-16"
        data-aos="fade-in"
      >
        <h2 className="font-normal main-text">Welcome to</h2>
        <span className="font-bold main-text">Ribbon Protocol</span>
        <p className="mx-auto sm:mt-5 mt-3 text-base sm:text-lg">
          Ribbon Protocol is the operating system for Web3 HealthFi: a global
          health financing & health information system platform for tokenized
          primary healthcare and universal health coverage, that leverages high
          value health information NFTs as a secure collateral digital asset
          class to back public health development finance.
        </p>
      </section>
    </Hero>
  );
};

const Hero = styled.div`
  .main-text {
    font-size: 3rem;
    line-height: 3.3rem;
  }

  h2 {
    color: #89befb;
    font-family: "Greycliff-DemiBold", sans-serif;
  }

  span {
    color: #ff6495;
    font-family: "Greycliff-Bold", sans-serif;
  }

  .sec-two {
    p {
      max-width: 530px;
      font-family: "Greycliff-Regular", sans-serif;
    }
  }

  @media screen and (max-width: 450px) {
    .sec-two {
      p {
        max-width: 23rem;
      }
    }

    .main-text {
      font-size: 1.375rem;
      line-height: 1.87rem;
    }
  }

  @media screen and (max-width: 360px) {
    .sec-two {
      p {
        max-width: 20rem;
      }
    }
  }
`;

export default HeroPage;
