import React from 'react'
import styled from 'styled-components'

/* eslint-disable */


const HowCommon = ({num, title, text}) => {
    return (
        <How>
            <span className = 'num font-normal sm:mb-10 mb-5'>{num}</span>
            <p className = 'title sm:mb-2 mb-3'>{title}</p>
            <p className = 'text'>{text}</p>
        </How>
    )
}

const How = styled.div`
    span {
        color: #92BAF3;
        font-size: 2.5rem;
    }

    .title {
        color: #003168;
        font-family: "Greycliff-Bold", sans-serif;
        font-size: 1.5em;
    }

    .text {
        font-size: 1.125em;
        font-family: "Greycliff-Regular", sans-serif
    }

    @media screen and (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title {
            font-size: 1.2rem;
        }

        .text {
            font-size: 1rem;
        }
    }

`

export default HowCommon
