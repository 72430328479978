export const aboutData = [
  { text: "Games", to: "/about/games" },
  { text: "Protocol", to: "/about/protocol" },
  { text: "Incentives", to: "/about/incentives" },
  { text: "Technology", to: "/about/technology" },
  { text: "Information", to: "/about/information" },
  { text: "People", to: "/about/people" },
];

export const docsData = [
  { text: "Governance", to: "/docs/governance" },
  { text: "DeFi", to: "/docs/De-Fi" },
];

export const tokenData = [
  { text: "FAQ", to: "/token/help" },
  { text: "T%C", to: "/token/t&c" },
];