import { Gov } from "./styles";
import gov from "../../asset/desktop/gov/gov.png";
import govIcon from "../../asset/desktop/gov/govIcon.png";
import govR from "../../asset/desktop/gov/govR.png";
import govG from "../../asset/desktop/gov/govG.png";
import govA from "../../asset/desktop/gov/govA.png";
import group from "../../asset/desktop/Group.png";
import Title from "../../components/common/Title";
import AOS from "aos";
import "aos/dist/aos.css";
import LazyLoad from "react-lazyload";

AOS.init({
  delay: 50,
  duration: 400,
});
const Gorvern = () => {
  return (
    <Gov className="my-4 ">
      <div className="overlay relative overflow-y-hidden">
        <div className="section_one " data-aos="flip-left">
          <div className="media_content  h-7vh md:flex md:items-center md:justify-between p-4">
            <div className="empty_title md:ml-20 " data-aos="slide-right">
              <Title text="Governance" bgc="#FFF8FA" />
            </div>
            <div
              className="img_container  w-full  md:w-1/2 z-10 md:mr-16  mt-4 md:mt-11 p-8"
              data-aos="slide-left"
            >
              <LazyLoad once>
                <img src={gov} alt="" className="w-full h-full" />
              </LazyLoad>
            </div>
          </div>
          <div className="img hidden md:block z-10 absolute top-48 h-64 ">
            <img src={group} alt="" className="" />
          </div>
          <div className="info hey border relative bg-text-title w-full md:mt-6 h-7vh md:h-9vh md:px-28 p-9 flex items-center justify-center text-left ">
            <p className="text-lg text-white my-6 md:w-3/5" data-aos="zoom-in">
              The Ribbon Protocol is governed by its community, via forum
              discussions and, when pertinent, voting on proposals submitted on
              the Governance Forum. Major structural and protocol changes and
              use of the reserve funds will be voted on by the community,
              whereas smaller changes affecting operations, as well as
              maintenance of Ribbon DeFi, Ribbon Incentives App and menu farming
              pairs, are decided on by Ribbon Management and core team.
            </p>
            <div className="card absolute -bottom-1/2 md:-bottom-32 f w-11/12">
              <div className="relative w-full h-full bg-white border border-text-title py-11 px-8 space-y-8 rounded-2xl">
                <img src={govIcon} alt="" className="icon absolute -top-20" />
                <h1 className="title text-text-title text-2xl font-bold">
                  Patients First Philosophy
                </h1>
                <p className="info w-full md:w-3/4 text-xl">
                  The Ribbon Protocol embraces the Patient First healthcare
                  principle that puts the Patient at the center of care, and is
                  thus designed around the needs of the Patient, where all
                  design decisions and iterations are determined by empowering
                  the Patient as the primary beneficiary.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="section_two   bg-sec-pink mt-80 md:mt-48 flex flex-col">
          {/* text content */}
          <div className="gov_text text-center px-4  md:w-1/2 mx-auto ">
            <h1 className="title text-text-title text-4xl font-semibold mt-20">
              Governance Tokens
            </h1>

            <p className="mt-6 text-xl">
              The Ribbon Protocol uses multiple Governance Tokens to manage
              value, voting and to optimize Public Health sub-economies for
              sustainability. There are three primary Tokens i.e. Ribbon Token
              (RIBBON), Global Health Financing Token (GHF) and AFYA algorithmic
              stablecoin (AFYA) that link the whole system together in an
              infinity loop, where they pour value into each other to regenerate
              the cycle. Holders of these tokens can use them to access the
              Governance system to vote or to submit proposals.
            </p>
          </div>
          {/* text content */}

          <div className="image_content md:flex md:flex-row items-center md:justify-center md:py-20 md:px-28">
            <div
              className="img_container mx-auto w-96 mt-4 p-8 "
              data-aos="slide-right"
            >
              <img src={govR} alt="" className="w-full h-full" />
            </div>
            <div className=" hidden md:block w-20"></div>

            <div
              className="info text-left p-8  md:mt-6  md:h-80 md:w-1/2 "
              data-aos="slide-left"
            >
              <Title text="Ribbon Token" bgc="#fff" reg />
              <p className="text text-text-dark text-lg ">
                The RIBBON token is the anchor Governance token for the Ribbon
                Protocol that enables the decentralization of the protocol,
                where holders will be able to submit proposals and vote on
                protocol changes and updates. Public Health Validators will also
                be able to Stake RIBBON to validate transactions and to secure
                the Network.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="section_three bg-white md:py-20 md:flex md: flex-row-reverse  md:justify-center md:items-center mt-8">
        <div
          className="img_container w-full md:w-96 mt-4 p-8 md-1/2 flex justify-center items-center "
          data-aos="fade-right"
        >
          <img src={govG} alt="" className="w-64 md:w-full h-full" />
        </div>

        <div
          className="info text-left p-8 text-lg md:w-1/2 md:ml-16  "
          data-aos="fade-left"
        >
          <Title text="Global Health Finance Token" reg bgc="#fff" />
          <p className="text text-text-dark mt-8 text-md md:leading-6">
            The Global Health Financing Token (GHF) token is a governance token
            for Public Health Data and Health Information across the Ribbon
            Protocol, and enables the decentralization of the public health
            information, where holders will be able to submit proposals and vote
            on public health information regulation changes and updates. Public
            Health Validators and Stakers will also be able to Stake GHF to
            validate transactions and secure the Network. The GHF token is the
            rewards token earned by users who stake RIBBON token, and is also
            used for Institutional DeFi lending and borrowing across the Ribbon
            Protocol ecosystem to help close the global health financing gap.
          </p>
        </div>
      </div>

      {/*  */}

      <div className="section_four bg-sec-blue md:flex md:flex-row py-20 md:px-28 md:justify-center ">
        <div
          className="img_container mx-auto w-80 flex justify-center items-center mt-4  md:top-0"
          data-aos="fade-right"
        >
          <img src={govA} alt="" className="w-full  h-full " />
        </div>
        <div className=" hidden md:block w-20"></div>
        <div
          className="info text-left p-8 text-lg md:w-1/2 "
          data-aos="fade-left"
        >
          <Title text="AFYA Token" reg bgc="#fff" />
          <p className="text text-text-dark text-lg">
            The AFYA token is an algorithmic stablecoin with elastic supply,
            that is minted and backed using the RIBBON Token. AFYA’s has utility
            as the Protocol’s stablecoin that is earned by Users and Patients
            for completing specific health tasks in the Ribbon Health Incentives
            App, and is also used for DeFi lending and borrowing across the
            Ribbon Protocol.
          </p>
        </div>
      </div>
    </Gov>
  );
};

export default Gorvern;
