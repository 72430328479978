import React from 'react'
import InformationPage from '../../components/Information'
import Cards from './Cards'
import RibbonProtocol from './RibbonProtocol'

import inforPicture from '../../asset/desktop/DeFi/DeFi.png'
import info from '../../asset/desktop/Defi2.png'


import finance from '../../asset/desktop/DeFi/finance.png';
import bond from '../../asset/desktop/DeFi/bond.png';
import farming from '../../asset/desktop/DeFi/farming.png';
import Synthetics from '../../asset/desktop/DeFi/Synthetics.png';
import minting from '../../asset/desktop/DeFi/minting.png';
import Publish from '../../asset/desktop/DeFi/Publish.png';

import zigzag from '../../asset/desktop/Group.png'

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  delay: 50,
  duration: 400,
});

const DeFi = () => {

  const cardsArray = [
    {
      img: finance,
      heading: "finance",
      text: "Ribbon DeFi provides decentralized Lending and Borrowing services to individual, institutions and Governments, where health data is used as collateral."
    },
    {
      img: bond,
      heading: "Social Impact Bonds",
      text: "Ribbon DeFi offers the ability to crowdfund for Social Impact Bonds from the Crypto economy, and provides a Tax deductible investment vehicle for Crypto holders and organizations to invest in Social Impact."
    },
    {
      img: farming,
      heading: "Farming",
      text: "Ribbon DeFi provides access to earn earn High APR and APY from  low risk Liquidity Pooling and Staking."
    },
    {
      img: Synthetics,
      heading: "Synthetics",
      text: "Ribbon DeFi provides Patient access to invest in Tokenized Stocks of drugs, Pharmaceuticals and commodities they consume in their healthcare."
    },
    {
      img: minting,
      heading: "Minting",
      text: "Ribbon DeFi enables holders of the Ribbon & GHF tokens to mint Stablecoin tokens that can be lent out at interest or be used as incentives to capture downstream yield revenues."
    },
    {
      img: Publish,
      heading: "Publishing",
      text: "Use your GHF & RBN Tokens to approve the Publishing of your Health Data. Buy High Value Health Data, or stake your GHF/RBN Token pairs to earn variable % interest and mitigate against the exponential price increase of health data."
    },
  ]

  return (
    <div data-aos = 'slide-right'>
      <InformationPage
        title="DeFi"
        textShadow={inforPicture}
        headerImg={info}
      />
      <div className = 'relative'>
        <img src = {zigzag} alt = 'zig-zag' className = 'object-fit hidden sm:block absolute top-0 left-0 right-0' style = {{width: '100%'}} />
      </div>
      <Cards

        body1="Ribbon DeFi is a Decentralized Finance platform for Global Health and Public Health financing to bridge the global health financing gap, and for monetizing health incentives and trading health data. "

          cardsArray={cardsArray}
      />

      <RibbonProtocol />
    </div>
  )
}

export default DeFi
