import styled from "styled-components";

const Wrapper = styled.section`
  li {
    list-style-type: disc;
  }
`;

export default function TandC() {
  return (
    <Wrapper className="px-4 md:px-16">
      <h3 className="text-[#000852] text-xl md:text-3xl font-gcb text-center mb-6">
        Ribbon Protocol Token Sale Terms & Conditions
      </h3>

      <p className="text-lg">
        The Terms and Conditions (the “T&C” or “Terms”) apply to the buyer of
        the RIBBON TOKEN (hereinafter “RIBBON TOKEN”) and future user of the
        Ribbon Protocol platform and technologies and constitute an agreement
        between Ribbon Protocol and you. Please read these terms carefully
        before moving onto the token private sale, pre-sale, IDO and public
        listing. The T&C affect your obligations and legal rights, including,
        but not limited to, waiver of rights and limitation of liability. If you
        do not agree to these terms of sale, do not participate in the sale of
        the RIBBON TOKENS. By purchasing the RIBBON TOKENS during the token
        private sale, pre-sale (Pre-IDO) and crowdsale period (hereinafter the
        “Token Sale”), you will be bound by this T&C, and all terms incorporated
        by reference. Your purchase of RIBBON TOKEN is subject to this T&C. You
        may not use our website, services, platforms, and acquire RIBBON TOKENS,
        if you are under 18 years old, and if you are a citizen, resident,
        habitent or legal entity incorporated in Restricted Use Areas as
        described below.
      </p>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Applicability
        </h3>

        <p className="text-lg">
          The following T&C constitute the agreement (the “Agreement”) between
          Ribbon Protocol (hereinafter “Ribbon Protocol” or the “Ribbon
          Protocol” or “us” ), a Decentralized Internet Based Ribbon Protocol
          and YOU (hereinafter “User” or “you”), with respect to the purchase of
          the RIBBON TOKEN and the future use of the services offered through
          the Ribbon Protocol platform (hereinafter “Platform”). By using our
          services, you are agreeing to be bound by the T&C in its latest
          version. You are aware that the Ribbon Protocol may change this T&C at
          any time, on our sole discretion without any prior notice to you. Any
          changes will be effective immediately upon posting on our Website.
          Your continued use of the Ribbon Protocol Platform means that you
          accept any new or modified terms.
        </p>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Services</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Ribbon Protocol is creating a cryptocurrency – so called RIBBON
            TOKEN, for the Ribbon Protocol Ecosystem, that has the potential to
            solve Primary Healthcare & Health Information System problems for
            more than 1.2 billion people in Africa.
          </li>

          <li>
            Based on the Ethereum platform, RIBBON TOKEN will be issued as
            payment of services on the Ribbon Protocol platform, and a rewarding
            program for App Users and the community.
          </li>

          <li>
            Verified KYC enables clients to participate/contribute in the RIBBON
            Token sale.
          </li>

          <li>
            Ribbon Protocol does not have the legal qualification as a security,
            since it does not give any rights on dividends or interest, any
            ownership right or stake share or equivalent rights or any right to
            receive future revenue shares, or any other form of participation in
            or relating to the Ribbon Protocol, nor shall Ribbon Protocol
            holders have any influence or rights in the development or
            governance of the Ribbon Protocol. RIBBON, therefore, is not a share
            and does not give any right to participate in the general meetings
            of the Ribbon Protocol. RIBBON TOKEN is final and non-refundable.
            The acquisition and use of RIBBON TOKEN shall not be done for
            speculative usage.
          </li>

          <li>
            RIBBON TOKEN can be purchased during the Token Private Sale,
            Pre-Sale and Public Sale directly from the Ribbon Protocol or after
            the Token Sale at the Ribbon Protocol or as per Ribbon Protocol
            future recommendations and Listing Exchanges where available.
          </li>

          <li>
            Ribbon Protocol gives no warranties that RIBBON TOKENS will be
            exchangeable on specific platforms or that they shall remain
            exchangeable on exchange platforms or that they would be
            exchangeable on other exchanges.
          </li>

          <li>
            RIBBON TOKEN is not an investment advice, a security nor an
            investment product and any action, notice, communication, message,
            decision, managerial act, or omission of the mentioned shall not be
            understood and interpreted as such. Ribbon Protocol gives no
            guarantees as to the value of any of the RIBBON TOKENS and
            explicitly warns users that there is no reason to believe that
            RIBBON TOKEN will increase in value, and they might also decrease in
            value or lose their value entirely. You represent and warrant that
            you are acquiring RIBBON TOKENS for your own personal use and for
            your personal utility and not for investment or financial purposes.
            You also represent and warrant that you do not consider RIBBON TOKEN
            as a security and you understand that RIBBON TOKENS may lose all
            their value and that you are not purchasing RIBBON TOKENS as an
            investment and that you are aware of all other risks deriving from
            holding RIBBON TOKEN as specified in these Terms and you accept
            them.
          </li>

          <li>
            The Ribbon Protocol performs as an entry ticket and is required to
            qualify for to avoid risks related to volatility (edging). The
            Ribbon Protocol may manage the funds received through RIBBON TOKEN
            distribution, by trying to minimize and/or avoid risks related to
            volatility using its best efforts, based on the Ribbon Protocol’s
            sole discretion. You expressly agree to any measure the Ribbon
            Protocol may apply trying to avoid and/or minimize risks related to
            volatility.
          </li>

          <li>
            RIBBON TOKENS are unregulated. Ribbon Protocol is not a financial
            institution and is currently not under supervision of any financial
            supervisory authority. Ribbon Protocol does not provide any licensed
            financial services, such as investment services, fund management or
            investment advice. None of the information or analysis presented
            herein are intended to form the basis for any investment decision,
            no specific recommendations are intended, and Ribbon Protocol,
            websites, Apps and RIBBON TOKENS do not offer, are not and shall not
            be construed as investment or financial products. In the event of
            any regulatory changes that would impact the RIBBON TOKEN, Ribbon
            Protocol reserves the right to amend its Services, the Website and
            the RIBBON TOKEN.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Token distribution
        </h3>

        <p className="text-lg">
          Token distribution is not a public offering of equity or debt and
          consequently does not fall under the securities or any prospectus
          regulation. You can make contributions into a smart contract system in
          Ether, Bitcoin, Stablecoin (acceptable Digital Assets) or FIAT
          (according to accepted payment services) and receive RIBBON TOKEN in
          exchange. The Ribbon Protocol reserves the right to amend acceptable
          digital assets or FIAT at any time, based on its sole discretion. All
          acquisitions of RIBBON TOKENS shall be publicly accessible via the
          Ethereum & Binance Smartchain blockchain browsers and any other that
          will be added in future.
        </p>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Knowledge required{" "}
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            The User purchasing RIBBON TOKENS expressly acknowledges and
            represents that she/he/they have carefully reviewed the T&C and
            fully understands the risks, costs and benefits associated with the
            acquisition of this token as indicated in the T&C.
          </li>

          <li>
            The User undertaking to acquire RIBBON TOKENS in relation to the
            token sale should ensure that she/he understands and has significant
            experience of cryptocurrencies, blockchain systems and services, and
            that she/he fully understands the risks associated with the Token
            Sale as well as the mechanism related to the use and custody of
            cryptocurrencies.
          </li>

          <li>
            Ribbon Protocol shall not be responsible for any loss of RIBBON
            TOKENS or situations making it impossible to access to RIBBON
            TOKENS, which may result in any actions or omissions of the future
            User or any person undertaking to acquire RIBBON TOKENS.
          </li>

          <li>
            Ribbon Protocol shall not be responsible for any loss of RIBBON
            TOKENS or situations making it impossible to access to RIBBON
            TOKENS, which may result in any actions or omissions of the future
            User or any person undertaking to acquire RIBBON TOKENS.
          </li>

          <li>
            Ribbon Protocol is not a provider of (a) exchange services between
            virtual currency and fiat currency; (b) wallet or custodial services
            of credentials necessary to access virtual currencies or (c)
            cryptocurrency mining services. User expressly agrees and
            acknowledges that anytime throughout the duration of these Terms,
            RIBBON TOKENS may require material, technical, commercial or any
            other changes and understands that an upgrade or modification of the
            RIBBON TOKENS may be required. If a user decides not to participate
            in such an upgrade, the user acknowledges and agrees that
            non-upgraded RIBBON TOKENS may lose their functionality in full and
            that they may no longer use the RIBBON TOKEN.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Risks</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Development failure or abortion. Acquiring and storing RIBBON TOKENS
            involves various risks, in particular that Ribbon Protocol may not
            be able to launch some operations and continue developing its
            platform or community. Therefore, and prior to acquiring RIBBON
            TOKENS, any user should carefully consider the risks, costs, and
            benefits of acquiring RIBBON TOKENS within the Token Sale, and, if
            necessary, obtain independent advice in this regard. Any interested
            person who is not in the position to accept nor to understand the
            risks associated with the activity (incl. the risks related to the
            non-development of Ribbon Protocol network and operations) or any
            other risks as indicated in the T&C, should not acquire RIBBON
            TOKENS, at this stage or later.
          </li>

          <li>
            Legal Risk concerning Security Regulation. There is a risk that in
            some jurisdictions RIBBON TOKENS might be considered as a security,
            now or in the future. Ribbon Protocol does not give warranties or
            guarantees that RIBBON TOKENS are not a security in all
            jurisdictions. Each user of RIBBON TOKENS shall bear its own legal
            or financial consequences of RIBBON TOKEN being considered a
            security in their respective jurisdiction. The legal ability of
            Ribbon Protocol to provide RIBBON TOKENS in some jurisdictions may
            be eliminated by future regulation or legal actions. In the event,
            it turns out with a high degree of certainty that RIBBON TOKENS are
            not legal in certain jurisdiction, Ribbon Protocol will base on its
            sole discretion either <b /> (a) cease operations in that
            jurisdiction, or <br /> (b) adjust RIBBON TOKENS in a way to comply
            with the regulation should that be possible and viable. It is your
            obligation to check if acquisition and disposal of RIBBON TOKENS is
            legal in your jurisdiction, and by accepting these Terms you
            expressly agree and warrant that you will not use RIBBON should
            their use not be legal in the relevant jurisdiction.
          </li>

          <li>
            Risk of malfunction of blockchain. Nobody can guarantee the Ethereum
            & Binance Sart Chain source code used by Ribbon Protocol will be
            flaw-free. It may contain certain flaws, errors, defects and bugs,
            which may disable some functionality for users, expose users’
            information or otherwise. Such flaw would compromise the usability
            and/or security of Ribbon Protocol and consequently bring adverse
            impact on the value of Ribbon Protocol open source codes rely on
            transparency to promote community-sourced identification and
            solution of problems within the code.
          </li>

          <li>
            Update of the Source Code. The Ethereum & Binance Smart Chain source
            code could be updated, amended, altered or modified from time to
            time by the developers and/or the community of Ethereum and Binance
            Smart Chain. Nobody is able to foresee or guarantee the precise
            result of such update, amendment, alteration or modification. As a
            result, any update, amendment, alteration or modification could lead
            to an unexpected or unintended outcome that adversely affects RIBBON
            TOKENS and/or Ribbon Protocol operation or market value.
          </li>

          <li>
            Internet transmission risks. You acknowledge that there are risks
            associated with utilizing an Internet-based digital assets, products
            and website including, but not limited to, the failure of hardware,
            software, and Internet connections. You acknowledge that Ribbon
            Protocol shall not be responsible for any communication failures,
            disruptions, errors, distortions or delays you may experience when
            using our services, website, platform, RIBBON TOKENS howsoever
            caused.
          </li>

          <li>
            Cryptography risks. You acknowledge that there are risks associated
            with cryptography, such as code cracking or technical advances such
            as the development of quantum computers, could present risks to all
            crypto-currencies including RIBBON TOKENS. This could result in the
            theft, loss, disappearance, destruction or devaluation of RIBBON
            TOKENS. To a reasonable extent, Ribbon Protocol will be prepared to
            take proactive or remedial steps to update the protocol underlying
            Ribbon Protocol in response to any advances in cryptography and to
            incorporate additional reasonable security measures where
            appropriate. It is impossible to predict the future of cryptography
            or the future of security innovations to an extent that would permit
            the Ribbon Protocol accurately guide the development of Ribbon
            Protocol to take into account such unforeseeable changes in the
            domains of cryptography or security.
          </li>

          <li>
            Forking. Ethereum & Binance Smart Chain are open source projects and
            supported by the community. The developers of the Ribbon Protocol do
            not lead the development, marketing, operation or otherwise of
            Ethereum or Binance Smart Chain. Anybody may develop a patch or
            upgrade of the source code of Ethereum’s source without prior
            authorization of anyone else. The acceptance of Ethereum patches or
            upgrades by a significant, but not overwhelming, percentage of the
            users could result in a “fork” in the blockchain of Ethereum and
            Binance Smart Chain, and consequently the operation of two separate
            networks and will remain separate until the forked blockchains are
            merged. The temporary or permanent existence of forked blockchains
            could adversely impact the operation and the market value of the
            RIBBON and in the worst case scenario, could ruin the sustainability
            of the Ribbon Protocol. While such a fork in the blockchain of
            Ethereum or Binance Smart Chain would possibly be resolved by
            community-led efforts to merge the forked blockchains, the success
            is not guaranteed and could take a long period of time to achieve.
          </li>

          <li>
            Risk of unfavorable regulatory action in one or more jurisdictions.
            Blockchain technologies have been the subject of scrutiny by various
            regulatory bodies around the world. RIBBON could be impacted by one
            or more regulatory inquiries or actions, including but not limited
            to restrictions on the use or possession of RIBBON, which could
            impede or limit their existence, permissibility of their use and
            possession, and their value.
          </li>

          <li>
            Risk of theft and hacking. Hackers or other groups or organizations
            may attempt to interfere with your third-party wallet, the Website
            or the availability of RIBBON TOKENS in any number of ways,
            including without limitation denial of service attacks, Sybil
            attacks, spoofing, smurfing, malware attacks, social engineering or
            consensus-based attacks.
          </li>

          <li>
            Risk of mining attacks. Ethereum blockchain and Binance Smart Chain,
            which is used for the RIBBON TOKENS, is susceptible to mining
            attacks, including but not limited to double-spend attacks, majority
            mining power attacks,“selfish-mining”attacks, and race condition
            attacks. Mining Attacks, as described above, may also target other
            blockchain networks, with which RIBBON TOKEN interacts with. Any
            successful attacks present a risk to the RIBBON TOKEN.
          </li>

          <li>
            Risk of loss of value and uninsured losses. Value of RIBBON TOKENS
            may fluctuate and you may suffer loss in value of such acquired
            RIBBON TOKEN. In addition to loss of value risk, RIBBON TOKENS are
            entirely uninsured and are unlike bank accounts or accounts at some
            other financial institutions.
          </li>

          <li>
            Unanticipated risks. Cryptocurrencies and blockchain technologies
            are new and untested technology and in addition to the risk outlined
            in these Terms, there are also unforeseeable risks that may
            materialize as unanticipated.
          </li>

          <li>
            You acknowledge, agree and warrant that you have been warned of the
            potential risks involved by using our services, website and product
            and other relevant technologies mentioned herein as well as that
            there may be other risks involved, which are not specified herein
            and that you fully accept such risks.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Important Disclaimer
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            The T&C shall not and cannot be considered as an invitation to enter
            into an investment. They do not constitute or relate in any way nor
            should they be considered as an offering of securities in any
            jurisdiction. The T&C do not include or contain any information or
            indication that might be considered as a recommendation or that
            might be used to base any investment decision. This document does
            not constitute an offer or an invitation to sell shares, securities
            or rights belonging to Ribbon Protocol or any related technology or
            associated to Ribbon Protocol. A whitelist will be used as an entry
            ticket and is required to qualify for direct access to the RIBBON
            TOKEN when it will be accessible and is not intended to be used as
            an investment.
          </li>

          <li>
            Any information in the T&C is given for general information purpose
            only and Ribbon Protocol does not provide with any warranty as to
            the accuracy and completeness of this information.
          </li>

          <li>
            The offering of RIBBON TOKENS is done in order to allow the
            utilization of software services and not for speculative purposes.
          </li>

          <li>
            Ribbon Protocol will be an overseeing entity managing the RIBBON
            platform. Ribbon Protocol is not a financial intermediary according
            to Federation Law and is not required to obtain any authorization
            for Anti Money Laundering purposes.
          </li>

          <li>
            Regulatory authorities are carefully scrutinizing businesses and
            operations associated with cryptocurrencies in the world. In that
            respect, regulatory measures, investigations or actions may affect
            Ribbon Protocol’s business and even limit or prevent it from
            developing its operations in the future.
          </li>

          <li>
            Any person undertaking to acquire RIBBON TOKENS must be aware that
            the Ribbon Protocol business model and the T&C may change or need to
            be modified because of new regulatory and compliance requirements
            from any applicable laws in any jurisdictions. In such cases, any
            person undertaking to acquire RIBBON TOKENS acknowledges and
            understands that neither Ribbon Protocol nor any of its affiliates
            shall be held liable for any direct or indirect loss or damages
            caused by such changes.
          </li>

          <li>
            Ribbon Protocol will do its best to launch its operations and
            develop the RIBBON platform. Any person undertaking to acquire
            RIBBON TOKENS acknowledges and understands that Ribbon Protocol does
            not provide any guarantee that it will manage to achieve it.
          </li>

          <li>
            Acquiring RIBBON TOKENS shall not grant any right or influence over
            Ribbon Protocol and governance to the users. These tokens will be
            issued by a technical process referred to as a «blockchain». This is
            an open source IT protocol over which the Ribbon Protocol has no
            rights or liability in terms of its development and operation. The
            token distribution mechanism will be controlled by a Smart Contract;
            this involves a computer program that can be executed on the
            Ethereum or Binance Smart Chain network or on another «blockchain»
            network that is compatible with the Smart Contract programming
            language. Users acknowledge and understand therefore that Ribbon
            Protocol (incl. its bodies and employees) assumes no liability or
            responsibility for any loss or damage that would result from or
            relate to the incapacity to use the RIBBON TOKEN expected in case of
            intentional misconduct or gross negligence.
          </li>

          <li>
            Ribbon Protocol is based on the Ethereum & Binance Smart Chain
            protocols. Therefore, any malfunction, unplanned function or
            unexpected operation of the Ethereum & Binance Smart Chain protocols
            may cause the RIBBON TOKEN network to malfunction or operate in a
            way that is not expected.
          </li>

          <li>
            Employees of Ribbon Protocol are allowed to operate with RIBBON
            TOKEN at market price if they are not in knowledge of information
            that may modify the price of the Token.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Eligibility, Representation and Warranties
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            You must be at least 18 years old to use our services, product and
            website. By using our services, products and accessing our website
            you represent and warrant that you: <br /> (a) are at least 18 years
            old;
            <br />
            (b) you have full power and authority to enter into this agreement
            <br />
            (c) have not been previously suspended or removed from using our
            services <br />
            (d) have not been placed on any of the sanctions lists, published
            and maintained by the United Nations, European Union, any EU
            country, UK Treasury and US Office of Foreign Assets Control (OFAC);
          </li>

          <li>
            If you are using the services on behalf of a legal entity, you
            represent and warrant that: <br /> (a) such legal entity is duly
            organized and validly existing under the applicable laws of the
            jurisdiction of the internet based organizations Ribbon Protocol;{" "}
            <br /> (b) you are duly authorized by such legal entity to act on
            its behalf <br /> (c) any beneficial owner of the legal entity,
            director, employee, services provider or any other individual in any
            way connected with the Ribbon Protocol has not been placed on any of
            the sanctions lists, published and maintained by the United Nations,
            European Union, any EU country, UK Treasury and US Office of Foreign
            Assets Control (OFAC);
          </li>

          <li>
            By participating in the Private Sale, Pre-Sale and Token Sale, the
            User agrees to the T&C and in particular, they represent and warrant
            that they: <br /> (a) are authorized and have full power to acquire
            RIBBON TOKEN according to the laws that apply in their jurisdiction
            of domicile; <br /> (b) are not a U.S. citizen, resident or entity
            (a “U.S. Person”) nor are they purchasing RIBBON TOKEN or signing on
            behalf of a U.S. Person; <br /> (c) are not a Chinese resident nor
            are they purchasing RIBBON TOKEN or signing on behalf of a Chinese
            resident; <br />
            (d) are not a South Korean resident nor are they purchasing RIBBON
            TOKEN or signing on behalf of a South Korean resident; <br /> (e)
            are familiar with all related regulations in the specific
            jurisdiction in which they are based and that purchasing
            cryptographic tokens in that jurisdiction is not prohibited,
            restricted or subject to additional conditions of any kind; <br />{" "}
            (f) are not acting for the purpose of speculative investment; <br />{" "}
            (g) live in a jurisdiction which allows Ribbon Protocol to sell the
            RIBBON TOKEN through a crowdsale without requiring any local
            authorization; <br /> (h) does not live in a jurisdiction which is
            qualifying token issued through a crowd sale as securities; <br />{" "}
            (i) will not use the Token Sale for any illegal activity, including
            but not limited to money laundering and the financing of terrorism;{" "}
            <br /> (j) are solely responsible for determining whether the
            acquisition of RIBBON TOKEN is appropriate for them; K. are
            acquiring RIBBON TOKEN exclusively for use of the Ribbon Protocol
            platform. <br /> (l) understand the risks associated with the Token
            Sale (incl. the risks related to the non development of Ribbon
            Protocol network and operations) and understand the use of
            cryptocurrencies and its associated risks. <br /> (m) acknowledges
            and accepts that the RIBBON TOKEN crowdsale is taking place within a
            legal environment that is still under development, shall not violate
            or attempt to violate the security of the website; services,
            platform and RIBBON TOKEN and you will not hack into, interfere
            with, disrupt, disable, overburden, modify, publish, reverse
            engineer, participate in the transfer or sale, create derivative
            works, or otherwise impair the website, platform and RIBBON TOKEN.
          </li>
        </ul>

        <p>
          Ribbon Protocol shall have the right to implement necessary measures
          to monitor compliance of this Section.
        </p>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Intellectual Property Rights
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            To the extent that copyright or other intellectual property rights
            exist on the Ribbon Protocol platform, websites, repositories,
            Social Media accounts, RIBBON TOKEN and services such as software,
            know-how, analysis or programs, those copyrights and other
            intellectual and industrial rights belong to Ribbon Protocol. Under
            no circumstances will these Terms be construed as granting, by
            implication, estoppel or otherwise, a license to any Intellectual
            Property or components thereof other than as specifically granted in
            these terms. You agree that Ribbon Protocol's Intellectual Property
            may be used only as provided in these terms. All rights not
            expressly granted herein are reserved.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Disclaimer of Warranties
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            To the fullest extent permissible under applicable law, our service,
            website, RIBBON TOKENS are provided on an “as is,” and “as
            available” basis, without warranties of any kind. no warranty of any
            kind, express, implied or statutory, including implied warranties of
            condition, uninterrupted use, merchantability, satisfactory quality,
            fitness for a particular purpose, non-infringement of third party
            rights, and warranties (if any) arising from a course of dealing,
            usage, or trade practice, is given in conjunction to website,
            services, platform, RIBBON TOKEN any software or tools contained in
            website, technologies, Ribbon Protocol and services and in general.
          </li>
          <li>
            Ribbon Protocol does not warrant that the service, RIBBON TOKEN or
            website will be error-free or uninterrupted or that any defects will
            be corrected in reasonable time. you acknowledge that your use of
            the service, platform and website is at your sole risk.
          </li>
          <li>
            While every effort is made to ensure that information contained in
            the website or Ribbon Protocol is correct, Ribbon Protocol disclaims
            all warranties regarding the accuracy, adequacy, correctness,
            completeness, reliability, timeliness, non-infringement, title,
            merchantability or fitness of any information on the Website and/or
            Ribbon Protocol
          </li>
          <li>
            Ribbon Protocol does not guarantee the confidentiality or privacy of
            any communication or information transmitted through services, on
            the Website or Ribbon Protocol or any site linked to the website. We
            will not be liable for the privacy or security of information,
            e-mail addresses, registration and any identification information
            (if applicable), disk space, communications, confidential or
            proprietary information, or any other content transmitted to us in
            any manner, or otherwise connected with your use of our services,
            website or platform.
          </li>
          <li>
            Ribbon Protocol shall not bear any liability, whatsoever, for any
            damage or interruptions caused by any computer viruses, spyware,
            scareware, trojan horses, worms or other malware that may affect
            your computer or other equipment, or any phishing, spoofing or other
            attack. We advise the regular use of a reputable and readily
            available virus screening and prevention software. You should also
            be aware that email services are vulnerable to spoofing and phishing
            attacks and should use care in reviewing messages purporting to
            originate from the Ribbon Protocol. Always log into your account
            through the website to review any transactions or required actions,
            if you have any uncertainty regarding the authenticity of any
            communication or notice.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Limitation of liability
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Ribbon Protocol, as well as its officers, directors, agents, joint
            ventures, employees, suppliers and advisors, assumes no liability or
            responsibility for any loss raised from the token sale, arising out
            of or related to the use of the Ribbon Protocol platform or any
            technical, interruption or malfunction of the Ribbon Protocol
            platform, website, token, services or otherwise. In no event shall
            Ribbon Protocol, as well as its officers, directors, agents, joint
            ventures, employees, suppliers and advisors, be liable for any
            special, indirect, incidental, punitive or consequential damages of
            any kind whatsoever, including without limitation any damages caused
            by or resuming from reliance by any user or any information obtained
            from the Ribbon Protocol, or that result from mistakes, omissions,
            interruptions, deletion of files or email, errors, defects, viruses,
            delays in operation or transmission or any failure of performance,
            whether or not resulting from a force majeure event, suspension of
            Ethereum & Binance Smart Chain network communication failure, theft,
            destruction or unauthorized access to Ribbon Protocol’s records,
            services, website, RIBBON TOKEN. In addition to the above, Ribbon
            Protocol shall not be liable for any loss of profit, loss of
            business, trading losses, loss in value, or any other loss. This
            applies even if such damages are foreseeable.
          </li>

          <li>
            The limitation of liability set out above shall not be applicable in
            the event that Ribbon Protocol, or a Ribbon Protocol-employee, has
            caused the damage by intentional misconduct or by gross negligence.
          </li>

          <li>
            each party acknowledges that the fees payable hereunder reflects the
            allocation of risk set forth in the agreement and that each party
            would not enter into the agreement without these limitations on
            liability.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Indemnification
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            You will defend, indemnify and hold Ribbon Protocol, its affiliates
            and licensors and its officers, directors, contractors, employees,
            agents and representatives, harmless from and against all claims,
            actions, demands, liabilities, damages, losses, costs and expenses
            (including legal fees) relating to or arising from <br /> (a) your
            use of our services, website, platform, RIBBON TOKEN <br /> (b) your
            breach of these terms or any other liabilities arising out of your
            use of the services, website and product or the use by any other
            person accessing the website, service, platform, RIBBON TOKEN using
            your computer and/or your personal information <br /> (c) your
            violation of any rights of any other person or entity in connection
            with these terms
            <br /> (d) third-party claims concerning these terms or you thereof.
            You will promptly notify us of any third-party claims, actions, or
            proceedings which you become aware of that involves Ribbon Protocol
            as a result of these terms. Ribbon Protocol may participate with
            counsel of its own choosing in any proceedings described above.
            Ribbon Protocol will be required to respond to compulsory legal
            orders, you will reimburse Ribbon Protocol for reasonable attorney
            and contractors fees for their time and material spent responding to
            obligatory legal orders. You will not agree to any settlement which
            imposes any obligation or liability for Ribbon Protocol without
            Ribbon Protocol's prior written consent.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Links to other Websites
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Our services, websites, Apps, platform, RIBBON TOKEN may include
            links to third party websites, third party wallet providers or other
            service providers (“Third Party Sites”). If you use any links made
            available through our services, website, platform, application to
            reach other websites not maintained by the Ribbon Protocol you will
            leave our services, website, platform, application. The linked sites
            are not under the control of the Ribbon Protocol and Ribbon Protocol
            is not responsible for the content of any linked site or any link
            contained in a linked site. The linked sites are governed by the
            terms and conditions of that site.
          </li>

          <li>
            Ribbon Protocol shall not be responsible or liable, either directly
            or indirectly, for any damage or loss caused or alleged to be caused
            in connection with your use or reliance on any such content,
            information, statements, representations, advertising, products,
            services or other materials available on or through third party
            sites. Ribbon Protocol provides these links to you only as a
            convenience and the inclusion of any link does not imply
            recommendation, approval or endorsement by Ribbon Protocol of third
            party sites, nor does it imply that the linked third party sites
            recommends, approves of, or endorses Ribbon Protocol.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Feedback</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            In connection with your use of our services, website, platform, you
            may provide us with your feedback (suggestions, ideas, reviews,
            comments, material and questions or other information and/or
            material). We will own exclusive rights, including all intellectual
            property rights, to any feedback, suggestions, ideas or other
            information or materials you provide to us, whether by email,
            posting through our services, website, platform or otherwise. You
            waive any rights you may have to the feedback (including any
            copyrights to the extent permitted by law). And Feedback you provide
            to us is non- confidential, non-proprietary and is not subject to a
            third-party license and we have no liability for any loss or damage
            suffered by you as a result of the disclosure or use of such
            feedback. We have the right to disclose your identity to any
            third-party who is claiming that any content posted by you
            constitutes a violation of their intellectual property rights, or of
            their right to privacy. We have the right to remove any posting you
            make on our website if, in our opinion, your post does not comply
            with the content standards set out in this section.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Term and Termination
        </h3>

        <p className="text-lg">
          This agreement is entered into for unlimited duration. We reserve the
          right to terminate and/or suspend your access to our services/website,
          without giving you prior written notice and delete all related
          information and files related to your use of our services, website,
          platform, mobile application, without liability to you, at any time
          for any reason, including, but not limited to, if based on our sole
          discretion believe: <br /> (i) You have violated these Terms, <br />{" "}
          (ii) You create risk or possible legal exposure for us.
        </p>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Notices</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            You agree and consent to receive electronically all communications,
            agreements, documents, receipts, notices and disclosures
            (collectively, Communications”) that Ribbon Protocol provides in
            connection with your use of Ribbon Protocol Services, Apps,
            Websites, Platform and mobile application. You acknowledge and agree
            that Ribbon Protocol may provide these communications to you by
            posting them on its Website or by emailing them to you at the email
            address you provide. Any email communication will be considered to
            be received by you within 24 hours after we email it to you and
            Communication via our Website will be effective upon posting. You
            should maintain copies of electronic Communications by printing a
            paper copy or saving an electronic copy.
          </li>

          <li>
            It is your responsibility to keep your email address with the Ribbon
            Protocol up to date so that the Ribbon Protocol can communicate with
            you electronically. You understand and agree that if the Ribbon
            Protocol sends you an electronic communication but you do not
            receive it because your email address is incorrect, out of date,
            blocked by your service provider, or you are otherwise unable to
            receive electronic communications, Ribbon Protocol will be deemed to
            have provided the communication to you. Please note that if you use
            a spam filter that blocks or re-routes emails from senders not
            listed in your email address book, you must add the Ribbon Protocol
            to your email address book so that you will be able to receive the
            communications we send to you. You can update your email address,
            mobile phone number or street address at any time by logging into
            your Ribbon Protocol Apps or accounts or by sending such information
            to:
            <a href="mailto:hello@ribbonprotocol.org" className="text-blue-400">
              hello@ribbonprotocol.org
            </a>
            . You may contact us by sending an email to{" "}
            <a href="mailto:hello@ribbonprotocol.org" className="text-blue-400">
              hello@ribbonprotocol.org
            </a>{" "}
            with general questions. All legal notices (including legal disputes)
            must be sent in writing to the following email address:{" "}
            <a href="mailto:hello@ribbonprotocol.org" className="text-blue-400">
              hello@ribbonprotocol.org
            </a>
            , return receipt requested and addressed to the authorized
            representative of the relevant Party. All given notices must be in
            the English language.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Taxes</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            You are solely responsible and liable to declare, bear and pay all
            taxes, duties, imposts, levies, tariffs and surcharges that might be
            imposed by the laws and regulations of any jurisdiction as a result
            of or in connection with the use of services, apps, websites,
            platform, RIBBON TOKEN or any other Ribbon Protocol product. Ribbon
            Protocol is not giving any advice regarding tax issues and is not
            responsible for determining any tax implications connected with its
            Services, Apps, Websites, Platform, and the RIBBON TOKEN. Ribbon
            Protocol makes no representation as to the tax implication of any
            jurisdiction. You shall be solely liable for all such penalties,
            claims, fines, punishments, liabilities or otherwise arising from
            his/her underpayment, undue payment or belated payment of any
            applicable tax.
          </li>

          <li>
            All fees and charges payable by you to Ribbon Protocol are exclusive
            of any taxes and if certain taxes shall be applicable, they shall be
            added on top of applicable amount. Upon our request, you will
            provide us any information we reasonably request to determine
            whether we are obligated to collect VAT from you, including your VAT
            identification number. If any deduction or withholding is required
            by law, you will notify us and will pay us any additional amounts
            necessary to ensure that the net amount that we receive, after any
            deduction and withholding, equals the amount we would have received
            if no deduction or withholding had been required. Additionally, you
            will provide us with documentation showing that the withheld and
            deducted amounts have been paid to the relevant taxing authority.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Severability
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            If any of the provisions of the T&C or of the Agreement are deemed
            to be invalid, void or unenforceable, the remaining provisions shall
            continue in full force and effect.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Applicable Law and Jurisdiction
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            The T&C are subject to and governed by UK Law to the exclusion of
            International Private Law and any International Treaties. Any User
            of the Ribbon Protocol and RIBBON TOKEN agree to seek an amicable
            settlement prior to bringing any legal action All disputes arising
            from or under these T&C shall be resolved by arbitration in
            accordance with the UK Rules of International Arbitration of the UK
            Chambers of Commerce in force on the date when the Notice of
            Arbitration is submitted in accordance with these Rules. The
            arbitration panel shall consist of one arbitrator only. The seat of
            the arbitration shall be London, UK. The arbitral proceedings shall
            be conducted in English.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Applicable Law and Jurisdiction
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            The T&C are subject to and governed by UK Law to the exclusion of
            International Private Law and any International Treaties. Any User
            of the Ribbon Protocol and RIBBON TOKEN agree to seek an amicable
            settlement prior to bringing any legal action All disputes arising
            from or under these T&C shall be resolved by arbitration in
            accordance with the UK Rules of International Arbitration of the UK
            Chambers of Commerce in force on the date when the Notice of
            Arbitration is submitted in accordance with these Rules. The
            arbitration panel shall consist of one arbitrator only. The seat of
            the arbitration shall be London, UK. The arbitral proceedings shall
            be conducted in English.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">Privacy</h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Security measures. We protect your information using physical,
            technical, and administrative security measures to reduce the risks
            of loss, misuse, unauthorized access, disclosure, and alteration.
            Some of the safeguards we use are firewalls and data encryption,
            physical access controls to our data centers, and information access
            authorization controls. We also authorize access to personal
            information only for those employees who require it to fulfill their
            job responsibilities.
          </li>
          <li>
            Usage and sharing. We use personal information to provide our
            services and products, for administering our business activities, to
            communicate with you, to promote safety and security (we use
            personal information for loss prevention, anti- fraud and/or
            unauthorized activity and to enforce these terms) and to provide you
            with customer service, technical support, for audit purposes, to
            prevent and investigate potentially prohibited or illegal activates
            and to notify you about improvements to our website and new services
            we think you will find useful. We can use personal information so as
            to mitigate the risk of fraud, identity theft or credit card theft.
            We may use your name and email address to provide you with
            information about products or services that may be of interest to
            you. You may access, review and edit your personal information or
            ask us to delete your personal information at any time by logging in
            to the website using your credentials or by emailing us at:
            <a href="mailto:hello@ribbonprotocol.org" className="text-blue-400">
              hello@ribbonprotocol.org
            </a>{" "}
            We may also create anonymous, aggregated and/or other de-identified
            data records (“anonymous Information”) from information that you
            provide to us by excluding information (such as your name) that
            makes the information personally identifiable to you. We use this
            anonymous information to analyze request and usage patterns so that
            we may enhance the content of our services and to improve site
            navigation. We reserve the right to use anonymous information for
            any purpose and to disclose such data to third parties at our
            discretion.
          </li>

          <li>
            Sharing of personal data. We take your privacy very seriously, and
            will only share your personal information as needed with the
            entities within the ecosystem to perform the services and in the
            event if we are legally to meet any legal, regulatory, or
            governmental request, including but not limited to events when we
            are compelled to do so by a subpoena, court order, or similar legal
            procedure;
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h3 className="text-[#8A8FB6] text-lg md:text-2xl mb-2">
          Miscellaneous
        </h3>

        <ul className="pl-2 md:pl-8 text-lg">
          <li>
            Entire Agreement. These terms represent the entire agreement, and
            supersede all prior and contemporaneous understandings between you
            and the Ribbon Protocol regarding the subject matter, especially
            regarding the services, website, apps, platform and RIBBON TOKEN. In
            the event of any conflict between these terms and any other
            agreement you may have with the Ribbon Protocol, the terms of that
            other agreement will prevail only if these terms are specifically
            identified and declared to be overridden by such other agreement.
          </li>
          <li>
            Assignment. These Terms shall be binding upon and inure to the
            benefit of the parties and their permitted successors and assigns.
            You may not assign or transfer any of your rights or obligations
            under these Terms without Ribbon Protocol’s prior written consent
            from, including by operation of law or in connection with any change
            of control. Ribbon Protocol may assign or transfer any or all of its
            rights under these Terms, in whole or in part, without obtaining
            your prior written consent or approval.
          </li>

          <li>
            Act of God Events. Ribbon Protocol shall not be liable for; <br />
            (1) any inaccuracy, error, delays or omission of <br /> (i) any
            information, or <br />
            (ii) the transmission or delivery of information; <br />
            (2) any loss or damage arising from flood, extraordinary weather
            conditions, earthquake, or other act of God, fire, war,
            insurrection, riot, labor dispute, accident, action of government,
            communications, power failure, or equipment or software malfunction
            or any other cause beyond Ribbon Protocol's reasonable control
            (each, aka“Force Majeure Event”).
          </li>

          <li>
            Waiver. Ribbon Protocol failure or delay in exercising any right,
            power or privilege under these Terms shall not operate as a waiver
            thereof.
          </li>
        </ul>
      </div>
    </Wrapper>
  );
}
