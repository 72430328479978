import LazyLoad from "react-lazyload";
import styled from "styled-components";
import Line from "../../asset/desktop/line.png";

const Index = ({ title, textShadow, headerImg }) => {
  return (
    <Information>
      <section className="flex flex-col sm:flex-row sm:justify-between sm:items-center pl-4 sm:pl-14 mt-10 sm:mt-0">
        <LazyLoad once>
          <div className="info">
            <img src={textShadow} alt="info" />
            <div>
              <img src={Line} alt="line" />
              <p>{title}</p>
            </div>
          </div>
        </LazyLoad>

        <div>
          <img src={headerImg} alt="info" />
        </div>
      </section>
    </Information>
  );
};

const Information = styled.div`
  .info {
    position: relative;

    div {
      position: absolute;
      top: 0;
      color: #161f6d;
      font-size: 3.265rem;
      font-family: "GreyCliff-Bold", sans-serif;
    }
  }

  @media screen and (max-width: 650px) {
  }
`;

export default Index;
