import LazyLoad from "react-lazyload";
import Ribbontokens from "../../asset/desktop/Ribbontokens.png";
import Group from "../../asset/desktop/Group.png";
import Title from "../common/Title";
import RibbonTokenImage from "../../asset/desktop/gov/govR.png";
import ChartOne from "../../asset/desktop/Chart2.png";
import Roadmap from "../../asset/desktop/roadmap.png";
import SDG from "../../asset/desktop/sdg.png";
import line from "../../asset/desktop/line.png";
import StreamImage from "../../asset/desktop/stream.png";
import HealthCare from "../../asset/desktop/healthCare.png"

export default function TokenHero() {
  return (
    <div className="relative overflow-x-hidden">
      {/* <div className="md:flex flex-row items-center justify-between gap-y-16 mb-8 px-4 md:px-12 xl:px-20">
        <div className="mb-16 md:mb-0">
          <Title text="Tokenomics" bgc="#FFF8FA" />
        </div>
        <div className="flex mr-10 md:mr-0 justify-center ">
          <LazyLoad once>
            <img src={StreamImage} alt = "img" />
          </LazyLoad>
        </div>
      </div> */}

      <div className="absolute top-80">
        <img src={Group} alt="group" className="w-full h-full" />
      </div>

      {/* ABout Ribbon */}
      <section className="bg-[#161F6D] text-white py-24 px-4">
        <div className="max-w-[48rem] mx-auto">
          <Title text="About Ribbon Protocol" textColor="#EBEBEB" reg />
          <p className="mt-4">
            The Ribbon Protocol is a Web3 Global Health Financing & Health
            Information System for Universal Healthcare. The core product is
            health data, which is processed into analytics and NFTs for sale and
            also used as collateral to back loans from Crypto community to
            primary healthcare programs.
          </p>
        </div>
      </section>

      {/* Ribbon Token */}
      <section className="bg-[#FFF8FA]">
        <div className="image_content md:flex md:flex-row items-center md:justify-center md:py-20 md:px-28">
          <div
            className="img_container mx-auto md:w-[44rem] mt-4 p-8 "
            data-aos="slide-right"
          >
            <img src={RibbonTokenImage} alt="" className="w-full h-full" />
          </div>
          <div className=" hidden md:block w-20"></div>

          <div
            className="info text-left p-8 md:mt-6 md:w-1/2 "
            data-aos="slide-left"
          >
            <Title text="Ribbon Token" bgc="#fff" reg />
            <p className="text text-text-dark text-lg mt-4">
              The Ribbon Token is the governance token for the Ribbon Protocol
              and represents the underlying value of the Ribbon ecosystem. It is
              used as money for payments in the ecosystem and or exchanged for
              other Cryptocurrencies or into fiat currency via an exchange
              service or peer to peer service where available. The Ribbon token
              is also used as a security layer to secure the Ribbon Protocol
              Network and also acts as a governance token to empower users to
              submit proposals and vote on fundamental protocol changes and
              improvements. The token is also used for DeFi investment into the
              protocol, staking, farming, and for incentivizing users and
              investors.
            </p>
          </div>
        </div>
      </section>

      {/* Airdrop */}

      <section className="grid grid-cols-1 md:grid-cols-2 md:px-28 gap-y-6 gap-x-12 md:py-20 px-4 py-8 md:mt-6 items-center">
        <div className="info text-left">
          <Title text="Ribbon Token Airdrop" bgc="#fff" reg />
          <p className="text text-text-dark text-lg mt-4">
            The Ribbon Token will be airdropped to around 400 million patients
            through 98,745 verified primary healthcare facilities across 46
            countries in Sub-Saharan Africa, that they utilize for primary
            healthcare. The healthcare centers include verified formal and
            informal public clinics, hospitals, NGO healthcare centers and such
            with a verified GPS location, address and staff complement to help
            with user adoption.
            <br />
            <br />
            The initial airdrop will incentivize user adoption and Wallet
            engagement to collect required health information that will be
            tokenized into collateral and used to monetize the Protocol and back
            the Ribbon Token.
            <br />
            <br />
            An allocation of the Airdrop will also be distributed to the Ribbon
            Protocol Community, Developers, Team, Investors, Supporters,
            Partners, Collaborators and early adopters who have played a role
            along the way.
          </p>
        </div>

        <div>
          <LazyLoad once>
            <img src={HealthCare} alt="img" />
          </LazyLoad>
        </div>
      </section>

      {/* Ribbonomics */}

      <section className="bg-[#F0F7FF] px-4 md:px-0 py-8 md:py-16">
        <h3 className="text-center text-2xl md:text-[2.5rem] text-[#003168] font-gcb mb-8 md:mb-12">
          Ribbon Tokenomics (Ribbonomics)
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 md:max-w-[70rem] mx-auto">
          <div className="grid gap-y-3 md:gap-y-4">
            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495]"></div>
              <div className="max-w-[18.75rem]">
                <p className="text-normal">Properties:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  Stake = YES, Burn = YES, Mint = No, PAUSE = YES, Blacklist =
                  YES, Deflation = YES, Vote = YES
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495]"></div>
              <div className="max-w-[18.75rem]">
                <p className="text-normal">Utility:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  Utility: Payments, Collateral, Staking, Farming, Governance,
                  Rewards/Incentives, In-Game & Metaverse payments and
                  incentives Token
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495]"></div>
              <div className="max-w-[18.75rem]">
                <p className="text-normal">Emission:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  Emission: Unlocks based on rate of Ribbon Health App user
                  adoption (Expected Max Emission Timeline = 7 years)
                </div>
              </div>
            </div>
          </div>

          {/* four */}
          <div className="grid gap-y-3 md:gap-y-4 md:place-items-end md:text-right mt-3 md:mt-0">
            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495] block md:hidden"></div>

              <div className="max-w-[18.75rem]">
                <p className="text-normal">Ribbon Token Ticker:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  TBA
                </div>
              </div>

              <div className="h-4 w-4 rounded-full bg-[#FF6495] hidden md:block"></div>
            </div>

            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495] block md:hidden"></div>

              <div className="max-w-[18.75rem]">
                <p className="text-normal">Chains:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  Ethereum, Polygon, Binance Smart Chain, xDai
                </div>
              </div>

              <div className="h-4 w-4 rounded-full bg-[#FF6495] hidden md:block"></div>
            </div>

            <div className="flex items-center gap-4">
              <div className="h-4 w-4 rounded-full bg-[#FF6495] block md:hidden"></div>

              <div className="max-w-[18.75rem]">
                <p className="text-normal">Total Circulation:</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  21 Billion
                </div>
              </div>

              <div className="h-4 w-4 rounded-full bg-[#FF6495] hidden md:block"></div>
            </div>

            <div className="flex items-center gap-4 ">
              <div className="h-4 w-4 rounded-full bg-[#FF6495] block md:hidden"></div>

              <div className="max-w-[18.75rem]">
                <p className="text-normal">Initial Circulation (20%):</p>
                <div className="font-bold text-base md:text-lg text-[#050C43]">
                  4.2 Billion
                </div>
              </div>

              <div className="h-4 w-4 rounded-full bg-[#FF6495] hidden md:block"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-center py-12 md:py-20">
        <h1 className="text-[#003168] text-2xl md:text-[2.5rem] font-bold">
          Ribbon Token Allocation
        </h1>

        <div className="flex justify-center">
          <LazyLoad once>
            <img src={ChartOne} alt="chart1" />
          </LazyLoad>
        </div>
      </section>

      <section className="relative">
        <div className="absolute flex justify-center top-5 md:top-20 w-full">
          <h1 className="text-[#003168] text-2xl md:text-[2.5rem] font-bold">
            Ribbon Protocol Roadmap
          </h1>
        </div>

        <LazyLoad once>
          <img src={Roadmap} alt="roadmap" className="w-full" />
        </LazyLoad>
      </section>

      <section className="flex md:justify-between flex-col md:flex-row md:items-center md:px-16 px-4 py-12 md:py-20 gap-y-6">
        <div>
          <div>
            <img src={line} className="mb-3" />
            <h1 className="mb-5 text-[#003168] text-2xl md:text-[2.215rem] font-bold">
              Sustainable Development Goals (SDG)
            </h1>
          </div>

          <p className="text-lg max-w-[40rem]">
            The Ribbon Protocol is committed to the 2030 Sustainable Development
            Goals (SDGs) and caters for eleven of the seventeen goals which are:
            The Ribbon Protocol is a contributor to eight of the United Nations
            Sustainable Development Goals (SDGs). Good Health & Well-being is
            one of the most important foundational pillars of the SDGs, and the
            Ribbon Protocol ecosystem is designed around this goal, which has an
            impact in supporting overall economic growth and the achievement of
            broader development goals.
          </p>
        </div>

        <div className="">
          <img src={SDG} alt="sdg" />
        </div>
      </section>
    </div>
  );
}
